import React from 'react';
import './Loading.scss';
import { connect } from 'react-redux';
import { Constants } from '../../Constants';

const Loading = ({ configAccount }) => {
    return (
        <div className="loading-container">
            <div className="loading-content">
                <img src={Constants.BASE_URL + configAccount.config?.logo_web} className="image" />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer
})

export default connect(mapStateToProps)(Loading);
import React, { useEffect, useState } from 'react';
import { getBanners } from '../../services/BannersServices';
import CarouselPhotos from '../Carousel/CarouselPhotos';

const BannerSlider = () => {

    const [banners, setBanners] = useState([])

    const fetchBanners = async () => {
        try {
            let response = await getBanners();
            setBanners(response.data.data.banners)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchBanners();
    }, [])

    return (
        <React.Fragment>
            <CarouselPhotos photos={banners} />
        </React.Fragment>
    );
}

export default BannerSlider;

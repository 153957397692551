import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { DashCircle, Facebook, XCircle } from 'react-bootstrap-icons';
import moment from 'moment';
import 'moment/locale/es';
import { addOffer, deleteOffer } from '../../services/OffersServices';
import { Constants } from '../../Constants';
import Loading from '../loading/Loading';
import { toast } from 'react-toastify';
import './ProductContent.scss';
import { logoutAction } from '../../redux/actions/UserActions';

const ProductContent = (props) => {

    const { nextOffer, sessionProps, id, description, logout, timestamp, offerQty, quantity, locationUbic, id_lot, price_base, bestUserNameOffer, status, bestUserIdOffer, participation, auction, offer: offer_ } = props

    const [loading, setLoading] = useState(false)
    const [manualOffer, setManualOffer] = useState(nextOffer)
    const [quantitySelect, setQuantitySelect] = useState(1)

    useEffect(() => {
        toast.configure();
        moment.locale('es');
    }, [])

    // when quantity change update quantitySelect
    useEffect(() => {
        if (sessionProps.account !== null && sessionProps.account.id === bestUserIdOffer) {
            setQuantitySelect(offerQty)
            return
        }
        setQuantitySelect(quantity)
    }, [quantity])

    const handleChange = async (event) => {
        const { value } = event.target;
        let valor
        if (auction.moneySymbol === 'USD' && nextOffer <= 100 && value) {
            valor = parseInt(value);
            setManualOffer(valor)
            return
        }
        else {
            valor = parseInt(Math.round(value / 10) * 10);
        }
        if (valor < nextOffer) {
            setManualOffer(nextOffer)
        }
        else {
            setManualOffer(valor)
        }
    }

    const offer = (suggestedValue = null) => {
        if (suggestedValue !== null) {
            _offer(suggestedValue);
            return;
        }
        let answer = false;
        if (nextOffer > 1000) {
            if (manualOffer >= nextOffer + nextOffer * 1.60) {
                answer = true;
            }
        }
        else {
            if (manualOffer >= nextOffer * 2) {
                answer = true;
            }
        }
        if (answer) {
            confirmAlert({
                title: `¿Esta seguro que desea continuar?`,
                message: `Su oferta de ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(manualOffer)} supera altamente la oferta actual`,
                buttons: [
                    {
                        label: 'Cancelar'
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            _offer()
                        }
                    }
                ]
            });
        }
        else {
            _offer()
        }
    }

    const _offer = async (suggestedValue = null) => {
        setLoading(true)
        try {
            let offer = nextOffer
            if (suggestedValue) {
                offer = suggestedValue
            } else if (manualOffer > nextOffer) {
                offer = manualOffer
            } else if (auction.moneySymbol === 'USD' && nextOffer <= 100 && manualOffer > offer_) {
                offer = manualOffer
            }
            await addOffer({
                token: sessionProps.account.token,
                id_lot: id,
                offer,
                is_suggested: suggestedValue ? true : false,
                qty: quantitySelect
            });
            setLoading(false)
            let msg = 'Tu oferta ha sido ingresada con éxito';
            if (suggestedValue) {
                msg = 'Tu oferta sugerida ha sido ingresada, queda pendiente de aprobación';
            }
            toast(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            setLoading(false)
            if (error.response?.status === 401) {
                logout();
                window.history.push(`/ingresar/${id}/0/lotes`);
            }
            else {
                toast.error("No fue posible ingresar tu oferta, reintentar nuevamente", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    const deleteOffer = (item) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='col-12 text-center align-items-center'>
                        <XCircle color='red' size={60} fontWeight={'bold'} />
                        <b><h2 className='text-danger'>Cancelar oferta</h2></b>
                        <b><p style={{ fontSize: 16 }} className='mt-4 mb-4'>¿Esta seguro que desea cancelar la oferta?</p></b>
                        <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                            <button className='rounded-pill btn btn-danger col-6' onClick={onClose}>Cancelar</button>
                            <button style={{ fontSize: 18 }} className='border-0'
                                onClick={() => {
                                    _deleteOffer(item)
                                    onClose();
                                }}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    const _deleteOffer = async (item) => {
        setLoading(true);
        try {
            await deleteOffer({
                token: sessionProps.account.token,
                id: item.offerId,
                lot: item.id
            });
            setLoading(false);
            toast(`La oferta ha sido cancelada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            setLoading(false);
            if (error !== undefined && error.response !== undefined && error.response?.status === 401) {
                logout();
                window.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    const countDownCancelOffer = ({ item }) => {
        try {
            if (sessionProps.account === null || sessionProps.account.id !== item.bestUserIdOffer) {
                return (<React.Fragment></React.Fragment>);
            }
            if (timestamp === null || item.timestampCancelOffer === null) {
                return (<React.Fragment></React.Fragment>);
            }
            let difference = new Date(item.timestampCancelOffer * 1000).getTime() - new Date(timestamp * 1000).getTime();
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            if (days === -1 && hours === -1 && minutes === -1 && seconds + 30 <= 30 && seconds + 30 >= 0) {
                return (
                    <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                        <button onClick={() => deleteOffer(item)} style={{ background: 'transparent', border: 'none', color: 'red', textDecoration: 'underline' }}>
                            {`Cancelar oferta (${seconds + 30})`}
                        </button>
                    </div>
                );
            }
            //
            return (<React.Fragment></React.Fragment>);
        } catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className="product-details-content mt-5 mt-md-0">

                <h5 className="primary-color mb-2">
                    Lote: {id_lot}
                </h5>
                <ul className='list-lote'>
                    <li><span>Cantidad:</span>
                        <div class="quantity-container">
                            <button class={`quantity-button red ${status !== Constants.STATUS_IN_PROGRESS || quantity <= 1 || quantitySelect <= 1 || (sessionProps.account !== null && sessionProps.account.id === bestUserIdOffer) ? 'disabled' : ''}`} onClick={() => { setQuantitySelect(quantitySelect - 1) }}
                                disabled={status !== Constants.STATUS_IN_PROGRESS || quantity <= 1 || quantitySelect <= 1 || (sessionProps.account !== null && sessionProps.account.id === bestUserIdOffer)}
                            >-</button>
                            <input type="text" class="quantity-input" value={`${quantitySelect}/${quantity}`} disabled />
                            <button class={`quantity-button green ${status !== Constants.STATUS_IN_PROGRESS || quantity === 1 || quantitySelect === quantity || (sessionProps.account !== null && sessionProps.account.id === bestUserIdOffer) ? 'disabled' : ''}`}
                                onClick={() => { setQuantitySelect(quantitySelect + 1) }}
                                disabled={status !== Constants.STATUS_IN_PROGRESS || quantity === 1 || quantitySelect === quantity || (sessionProps.account !== null && sessionProps.account.id === bestUserIdOffer)}>+</button>
                        </div>
                    </li>
                    {
                        locationUbic ? <li><span>Ubicación:</span><p className='d-inline ml-3' >{locationUbic}</p></li> : null
                    }
                    <li><span>Precio Base:</span><p className='d-inline ml-3' >{price_base > 0 ?
                        ` ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(price_base)}` : ' Sin precio inicial'}</p></li>

                    {
                        bestUserNameOffer &&
                        <li><span>Mejor postor:</span><p className='d-inline ml-2' > {bestUserNameOffer}</p></li>
                    }
                    {
                        status === Constants.STATUS_IN_PROGRESS &&
                        <li><span>Oferta actual:</span><p className='d-inline ml-2' > {` ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(offer_)}`}</p></li>
                    }
                </ul>

                <hr className='mt-4 mb-4' />

                <div dangerouslySetInnerHTML={{ __html: description }}></div>

                <hr className='mt-4 mb-4' />

                <div className="buy-checkbox-btn d-flex flex-column flex-md-row mb-4 col-12 justify-content-between align-items-md-center">
                    {
                        status === Constants.STATUS_IN_PROGRESS ?
                            <React.Fragment>
                                {
                                    sessionProps.account === null &&
                                    <Link to={`/ingresar/${id}/0/lotes`}>
                                        <button className={`btn primary-background text-white rounded-pill w-100 f-16 ${window.style_config?.buttons?.showHoverOfferButtons ? 'not-offered_hover' : ''}`}>
                                            INGRESAR PARA OFERTAR
                                        </button>
                                    </Link>
                                }
                                {
                                    (sessionProps.account !== null && sessionProps.account.id !== bestUserIdOffer) ?
                                        participation ?
                                            <Link to="#">
                                                <button className={`btn primary-background text-white rounded-pill w-100 f-16 ${manualOffer > nextOffer ? 'losing-animation' : 'losing'}
                                                ${window.style_config?.buttons?.showHoverOfferButtons ? 'losing_hover' : ''}`}
                                                    onClick={() => offer()}>
                                                    OFERTAR {` ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(
                                                        manualOffer !== undefined && manualOffer > props.manualOffer ? manualOffer : props.manualOffer)}`}
                                                </button>
                                            </Link>
                                            :
                                            <Link to="#">
                                                <button className={`btn primary-background text-white rounded-pill w-100 f-16`}
                                                    onClick={() => offer()}>
                                                    OFERTAR {` ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(
                                                        manualOffer !== undefined && manualOffer > props.manualOffer ? manualOffer : props.manualOffer)}`}
                                                </button>
                                            </Link>
                                        : null
                                }
                                {
                                    sessionProps.account !== null && sessionProps.account.id === bestUserIdOffer &&
                                    <Link to="#">
                                        <button className="btn secondary-background rounded-pill primary-color w-100 f-16" disabled={true}>
                                            <i className="fas fa-star"></i> MEJOR OFERTA
                                        </button>
                                    </Link>
                                }
                                {
                                    sessionProps.account !== null && sessionProps.account.id !== bestUserIdOffer &&
                                    <div className="item text-center" style={{ marginTop: '5px' }}>
                                        <div className="product-add-to-cart">
                                            <div className="input-counter" style={{ width: '100%', maxWidth: '100%' }} >
                                                <input type="number"
                                                    min={nextOffer <= 100 && auction.moneySymbol === 'USD' ? 1 : nextOffer}
                                                    step={nextOffer <= 100 && auction.moneySymbol === 'USD' ? 1 : 10}
                                                    placeholder="Ingresar monto manual" style={{ width: '100%', textAlign: 'center' }}
                                                    onChange={(event) => handleChange(event)} />
                                            </div>
                                        </div>
                                    </div>

                                }
                                {
                                    countDownCancelOffer({ item: props })
                                }
                                {
                                    !bestUserIdOffer && price_base > 0 ? (
                                        <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                                            <button onClick={() => {
                                                // confirmar y pide en un input el valor sugerido
                                                let suggestedValue = '';
                                                confirmAlert({
                                                    title: `Sugerir oferta`,
                                                    message: 'Ingrese el monto sugerido',
                                                    buttons: [
                                                        {
                                                            label: 'Cancelar'
                                                        },
                                                        {
                                                            label: 'Confirmar',
                                                            onClick: () => {
                                                                offer(suggestedValue)
                                                            }
                                                        }
                                                    ],
                                                    childrenElement: () => (
                                                        <>
                                                            <br />
                                                            <input
                                                                type="number"
                                                                placeholder='Monto sugerido'
                                                                onChange={(event) => suggestedValue = event.target.value} />
                                                        </>
                                                    )
                                                });
                                            }} style={{ background: 'transparent', border: 'none', color: 'blue', textDecoration: 'underline' }}>
                                                Sugerir oferta
                                            </button>
                                        </div>
                                    ) : null
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {
                                    status === Constants.STATUS_PENDING || status === Constants.STATUS_DELIVERED ?
                                        <React.Fragment>
                                            {
                                                sessionProps.account !== null && sessionProps.account.id === bestUserIdOffer ?
                                                    <Link to="#">
                                                        <button className="btn btn-primary winning w-100 f-16" disabled={true}>
                                                            <i className="fas fa-star"></i> SUBASTADO {auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(offer_)}
                                                        </button>
                                                    </Link>
                                                    :
                                                    <Link to="#" className="noHover">
                                                        <button className="btn btn-primary losing-finished w-100 f-16" disabled={true}>
                                                            SUBASTADO {auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(offer_)}
                                                        </button>
                                                    </Link>
                                            }
                                        </React.Fragment>
                                        : null
                                }
                                {
                                    status === Constants.STATUS_NOT_AUCTIONED &&
                                    <Link to="#" className="noHover">
                                        <button className="btn w-100 rounded-pill btn-danger f-16" disabled={true}>
                                            <DashCircle color={'white'} size={20} />&nbsp;&nbsp; NO subastado
                                        </button>
                                    </Link>
                                }
                            </React.Fragment>
                    }
                </div>

                {/*<div className="item mt-2">
                    <Link to="/ayuda">
                        <span className='primary-color font-weight-bold ' style={{ cursor: "pointer", textDecoration: 'underline', fontSize: '15px' }}>
                            Revis&aacute; las preguntas frecuentes
                        </span>
                    </Link>
                </div>*/}

            </div>
        </React.Fragment >
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductContent);
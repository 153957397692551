import React, { useState, useEffect } from 'react';
import Navbar from '../components/Layout/Navbar';
import Footer from '../components/Layout/footer/Footer';
import FacebookLogin from 'react-facebook-login';
import './Login.scss';
import { userLogin, userLoginFB, userLoginWithPin } from '../services/UserServices';
import { connect } from 'react-redux';
import { userSetStorageAction } from '../redux/actions/UserActions';
import Loading from '../components/loading/Loading';
import ForgetPassword from '../components/modals/ForgetPassword';
import ShowMessage from '../components/modals/ShowMessage';
import { Link } from 'react-router-dom';
import ForgetPin from '../components/modals/ForgetPin';

let title = 'Ingresar'

const Login = (props) => {

    const { y, segment, auction, } = props.match.params
    const { configAccount, setLocalStorage, history } = props

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [pin, setPin] = useState('')
    const [messageError, setMessageError] = useState('')
    const [loading, setLoading] = useState(false)
    const [showForgetPassword, setShowForgetPassword] = useState(false)
    const [showForgetPin, setShowForgetPin] = useState(false)
    const [messageErrorOffer, setMessageErrorOffer] = useState()
    const [color, setColor] = useState('red')

    useEffect(() => {
        if (configAccount?.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
    }, [configAccount])

    const handleChange = event => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value.replace(/\s+/g, ''))
        }
        if (name === 'password') {
            setPassword(value)
        }
        if (name === 'phone') {
            setPhone(value)
        }
        if (name === 'pin') {
            setPin(value)
        }
    }

    const login = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            let response = null
            if (configAccount.config?.simply_auth) {
                response = await userLoginWithPin({
                    phone,
                    pin
                });
            } else {
                response = await userLogin({
                    email,
                    password
                });
            }
            setLoading(false);
            if (auction && y && segment) {
                history.push(`/${segment}/${auction}/${y}`);
            }
            setLocalStorage(response.data.data);
        } catch (error) {
            setLoading(false);
            let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            if (msg == 'El sistema no le permite realizar nuevas compras porque tiene facturas pendientes de pago. Contáctese con nosotros para regularizar el pago.') {
                setColor('red');
                setMessageErrorOffer(msg);
            } else {
                setMessageErrorOffer(msg);
            }
        }
    }

    const loginFB = async (data) => {
        setLoading(true);
        try {
            let response = await userLoginFB({
                email: data.email,
                fbId: data.id,
                name: data.name,
                json: data
            });
            setLoading(false);
            if (auction && y && segment) {
                history.push(`/${segment}/${auction}/${y}`);
            }
            setLocalStorage(response.data.data);
        } catch (error) {
            setLoading(false);
            setMessageError(error.response.data.message);
        }
    }

    const responseFacebook = (response) => {
        loginFB(response);
    }

    const closeModal = async () => {
        setShowForgetPassword(false);
        setShowForgetPin(false);
    }

    const closeModalMessageErrorOffer = async () => {
        setMessageErrorOffer(false);
    }

    return (
        <React.Fragment>
            {showForgetPassword && <ForgetPassword close={() => closeModal()} />}
            {showForgetPin && <ForgetPin close={() => closeModal()} />}
            <Navbar />
            {loading && <Loading />}
            {messageErrorOffer && <ShowMessage msg={messageErrorOffer}
                close={() => closeModalMessageErrorOffer()} color={color} />}
            <section className="ptb-60">
                <div className="container login-area">
                    <div className="col-12">
                        <div className="col-md-5 mb-5">
                            <div className="login-content">
                                <div className="text-left d-flex flex-column">
                                    <h4 className='display-5 font-weight-bold'>INICIAR SESI&Oacute;N</h4>
                                    <hr className='col-12 yellow-separator position-absolute rounded-pill' style={{ top: '20px' }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <form className="login-form" onSubmit={login}>

                                    {
                                        !configAccount.config?.simply_auth ?
                                            <>
                                                <div className="form-group">
                                                    <input type="email" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} className="form-control rounded-pill"
                                                        placeholder='&#xf0e0;&nbsp;&nbsp;Email' name="email"
                                                        value={email} onChange={handleChange} required />
                                                </div>

                                                <div className="form-group">
                                                    <input type="password" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} className="form-control rounded-pill" placeholder="&#xf084;&nbsp;&nbsp;Contraseña" name="password"
                                                        value={password} onChange={handleChange} required />
                                                </div>

                                                <div className="col-12 d-flex justify-content-between mb-4 align-items-center">
                                                    <span style={{ cursor: 'pointer' }} onClick={() => setShowForgetPassword(true)} className="forgot-password mt-0 font-weight-bold">
                                                        <u>¿Olvidó su contraseña?</u>
                                                    </span>
                                                </div>
                                            </> :
                                            <>
                                                <div className="form-group">
                                                    <input type="text" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} className="form-control rounded-pill"
                                                        placeholder='&#xf095;&nbsp;&nbsp;Celular' name="phone"
                                                        value={phone} onChange={handleChange} required />
                                                </div>

                                                <div className="form-group">
                                                    <input type="password" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} className="form-control rounded-pill" placeholder="&#xf084;&nbsp;&nbsp;Pin" name="pin"
                                                        value={pin} onChange={handleChange} required />
                                                </div>

                                                <div className="col-12 d-flex justify-content-between mb-4 align-items-center">
                                                    <span style={{ cursor: 'pointer' }} onClick={() => setShowForgetPin(true)} className="forgot-password mt-0 font-weight-bold">
                                                        <u>¿Olvidó su pin?</u>
                                                    </span>
                                                </div>
                                            </>

                                    }

                                    <button type="submit" style={{ fontSize: '18px', fontWeight: 'bold' }} className="btn text-capitalize secondary-background rounded-pill primary-color font-weight-bold">Iniciar sesi&oacute;n</button>

                                    <div style={{ textAlign: 'center', color: 'red' }}>
                                        <label>{messageError}</label>
                                    </div>

                                    <div className='col-12 d-flex flex-row justify-content-center mt-5' style={{ textAlign: 'center' }}>
                                        <div className='col-2 col-md-4'><hr /></div>
                                        <div className='col-7 col-md-4'><span style={{ fontSize: '15px', color: 'gray' }}>O inicia sesi&oacute;n con</span></div>
                                        <div className='col-2 col-md-4'><hr /></div>
                                    </div>

                                    <div style={{ textAlign: 'center', width: '100%', marginTop: '1rem' }}>
                                        {
                                            configAccount.config?.facebook_app_id &&
                                            <FacebookLogin
                                                appId={configAccount.config?.facebook_app_id}
                                                autoLoad={false}
                                                fields="name,email"
                                                callback={responseFacebook}
                                                cssClass="facebook-button-class rounded-pill"
                                                icon="fa-facebook"
                                                textButton="Ingresar con Facebook"
                                                disableMobileRedirect={true}
                                                version="20.0"
                                            />
                                        }
                                    </div>

                                    <div className='col-12 d-flex flex-row justify-content-center mt-5 primary-color font-weight-bold'>
                                        ¿A&uacute;n no tienes cuenta? &nbsp;&nbsp; <u><Link className='primary-color' style={{ fontSize: '17px' }} to="/registrar">REGISTRATE AQUI</Link></u>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    setLocalStorage: (data) => {
        dispatch(userSetStorageAction(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { Component, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/footer/Footer';
import { getPageTermAndConditions } from '../../services/UserServices';
import Loading from '../../components/loading/Loading';
import { connect } from 'react-redux';
import './styles.scss';

const title = 'TÉRMINOS Y CONDICIONES';

const Sell = ({ configAccount }) => {

    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [head, setHead] = useState('')

    useEffect(() => {
        const fetchTerms = async () => {
            try {
                setLoading(true)
                let response = await getPageTermAndConditions();
                let { head, items } = response.data.data;
                setHead(head.dynamic_seccion)
                setItems(items)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        if (configAccount?.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
        fetchTerms()
    }, [configAccount])

    return (
        <React.Fragment>
            <Navbar />
            {loading && <Loading />}
            <section id="termAndConditions" className="faq-area ptb-60">
                <div className="container">
                    <div className="section-title mb-5">
                        <div className="text-center d-flex flex-column position-relative justify-content-center align-items-center">
                            <h4 className='display-5 font-weight-bold'>{title}</h4>
                            <hr className='col-3 yellow-separator position-absolute rounded-pill' style={{ top: '30px' }} />
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: head }} className="description-inner-html" />
                    {
                        items && items.length ?
                            <div className="faq-accordion">
                                <ul className="accordion">
                                    <Accordion allowMultipleExpanded={true}>
                                        {
                                            items.map(item =>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            {item.title}
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            )
                                        }
                                    </Accordion>
                                </ul>
                            </div>
                            : null
                    }
                </div>
            </section>
            <Footer />
        </React.Fragment >
    );
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer
});

export default connect(mapStateToProps, null)(Sell);
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Layout/Navbar';
import Footer from '../components/Layout/footer/Footer';
import { userRegister, verificarCI, verificarRUT, departaments as fetchDepartments, userRegisterSimply } from '../services/UserServices';
import Loading from '../components/loading/Loading';
import { userSetStorageAction } from '../redux/actions/UserActions';
import { connect } from 'react-redux';
import { Constants } from '../Constants';
import FacebookLogin from 'react-facebook-login';
import { userLoginFB } from '../services/UserServices';
import { Facebook } from 'react-bootstrap-icons';

let title = 'Registrar';

const Register = (props) => {

    const { setLocalStorage, history, external, configAccount } = props;
    const { y, auction, segment } = props.match.params;

    const [departaments, setDepartaments] = useState([])
    const [form, setForm] = useState({
        name: '',
        mobile: '',
        document: '',
        address: '',
        email: '',
        password: '',
        confirmPassword: '',
        type_document: Constants.TYPE_DOCUMENT_CI,
        city: '',
        departamentSelect: 16,
        pin: '',
    })
    const [messageError, setMessageError] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchDepartamens = async () => {
            try {
                let response = await fetchDepartments();
                setDepartaments(response.data.data.departaments);
            } catch (error) {
                console.log(error)
            }
        }
        fetchDepartamens();
    }, [])

    useEffect(() => {
        if (configAccount?.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
    }, [configAccount])

    const handleChange = (event, maxLength = null) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: maxLength ? value.slice(0, getMaxLength()) : value });
    }

    const simplyRegister = async (event) => {
        event.preventDefault();
        setLoading(true);
        setMessageError('');

        if (form.name.length < 2) {
            setMessageError('El nombre debe tener al menos 2 palabras');
            setLoading(false);
            return;
        }
        if (form.pin.length < 4) {
            setMessageError('El pin debe tener al menos 4 caracteres');
            setLoading(false);
            return;
        }

        try {
            let response = await userRegisterSimply({
                name: form.name,
                phone: form.mobile,
                pin: form.pin,
            });
            setLoading(false);
            setLocalStorage(response.data.data);
        } catch (error) {
            setMessageError(error.response.data.message);
            setLoading(false);
        }
    }

    const register = async (event) => {
        event.preventDefault();
        setLoading(true);
        setMessageError('');
        if (form.name.match(/(\w+)/g).length < 2) {
            setMessageError('El nombre debe tener al menos 2 palabras');
            setLoading(false);
            return;
        }
        if (form.password !== form.confirmPassword) {
            setMessageError('Las contraseñas no coinciden');
            setLoading(false);
            return;
        }
        if (form.password.length < 6) {
            setMessageError('La contraseña debe contener al menos 6 caracteres');
            setLoading(false);
            return;
        }
        if (form.document !== '' && form.type_document === Constants.TYPE_DOCUMENT_CI) {
            let isValid = await verificarCI(form.document);
            if (!isValid) {
                setMessageError('La CI ingresada no tiene un formato válido');
                setLoading(false);
                return;
            }
        }
        if (form.document !== '' && form.type_document === Constants.TYPE_DOCUMENT_RUT) {
            let isValid = await verificarRUT(form.document);
            if (!isValid) {
                setMessageError('El RUT ingresado no tiene un formato válido');
                setLoading(false);
                return;
            }
        }
        try {
            let response = await userRegister({
                email: form.email,
                password: form.password,
                departamentId: form.departamentSelect,
                name: form.name,
                phone: form.mobile,
                document: form.document,
                direction: form.address,
                type_document: form.type_document,
                city: form.city
            });
            setLoading(false);
            setLocalStorage(response.data.data);
        } catch (error) {
            setMessageError(error.response.data.message);
            setLoading(false);
        }
    }

    const getMaxLength = () => {
        if (form.type_document === Constants.TYPE_DOCUMENT_CI) {
            return 8;
        }
        if (form.type_document === Constants.TYPE_DOCUMENT_RUT) {
            return 12;
        }
        return 50;
    }

    const loginFB = async (data) => {
        setLoading(true);
        try {
            let response = await userLoginFB({
                email: data.email,
                fbId: data.id,
                name: data.name,
                json: data
            });
            setLoading(false);
            if (auction && y && segment) {
                history.push(`/${segment}/${auction}/${y}`);
            }
            setLocalStorage(response.data.data);
        } catch (error) {
            setLoading(false);
            setMessageError(error.response.data.message);
        }
    }

    const responseFacebook = (response) => {
        loginFB(response);
    }

    if (!configAccount.config) {
        return null;
    }

    return (
        <React.Fragment>
            {
                !external &&
                <Navbar />
            }
            {loading && <Loading />}
            <section className="ptb-60">
                <div className="signup-area container">
                    <div className="signup-content">
                        <div className="col-12 col-md-5 mb-5">
                            <div className="login-content">
                                <div className="text-left d-flex flex-column">
                                    <h4 className='display-5 font-weight-bold'>REGISTRARSE</h4>
                                    <hr className='col-12 yellow-separator position-absolute rounded-pill' style={{ top: '20px' }} />
                                </div>
                            </div>
                        </div>
                        {
                            !configAccount.config?.simply_auth ?
                                <form className="signup-form row" onSubmit={register}>
                                    <div className="form-group col-12 col-md-6">
                                        <input type="text" className="form-control  rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf007;&nbsp;&nbsp;Nombre completo" name="name"
                                            value={form.name} onChange={handleChange} required />
                                    </div>

                                    <div className="form-group col-12 col-md-6">
                                        <input type="email" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf0e0;&nbsp;&nbsp;Email" name="email"
                                            value={form.email} onChange={handleChange} required />
                                    </div>

                                    <div className="form-group col-12 col-md-6">
                                        <div style={{ display: 'flex' }}>
                                            <select className="form-control rounded-pill" name="type_document" onChange={handleChange} style={{ width: 'max-content', fontFamily: "FontAwesome" }}>
                                                <option value={Constants.TYPE_DOCUMENT_CI}
                                                    selected={form.type_document === Constants.TYPE_DOCUMENT_CI ? true : false}>CI</option>
                                                <option value={Constants.TYPE_DOCUMENT_RUT}
                                                    selected={form.type_document === Constants.TYPE_DOCUMENT_RUT ? true : false}>RUT</option>
                                                <option value={Constants.TYPE_DOCUMENT_OTHER}
                                                    selected={form.type_document === Constants.TYPE_DOCUMENT_OTHER ? true : false}>Otro</option>
                                            </select>
                                            <input type="text" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf2bb;&nbsp;&nbsp;documento" name="document"
                                                value={form.document} onChange={(e) => handleChange(e, true)} />
                                        </div>
                                    </div>

                                    <div className="form-group col-12 col-md-6">
                                        <input type="text" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf095;&nbsp;&nbsp;Celular" name="mobile"
                                            value={form.mobile} onChange={handleChange} required />
                                    </div>

                                    <div className="form-group col-12 col-md-6">
                                        <input type="text" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf041;&nbsp;&nbsp;Dirección" name="address"
                                            value={form.address} onChange={handleChange} required />
                                    </div>

                                    <div className="form-group col-12 col-md-3">
                                        <input type="text" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf041;&nbsp;&nbsp;Ciudad" name="city"
                                            value={form.city} onChange={handleChange} />
                                    </div>

                                    <div className="form-group col-12 col-md-3">
                                        <div className="select-box">
                                            <select className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} name="departamentSelect" onChange={handleChange}>
                                                {
                                                    departaments?.map(item =>
                                                        <option key={item.id} value={item.id} selected={item.id === 16 ? true : false}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group col-12 col-md-6">
                                        <input type="password" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf084;&nbsp;&nbsp;Contraseña" name="password"
                                            value={form.password} onChange={handleChange} required min="6" />
                                    </div>

                                    <div className="form-group col-12 col-md-6">
                                        <input type="password" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf084;&nbsp;&nbsp;Confirmar contraseña" name="confirmPassword"
                                            value={form.confirmPassword} onChange={handleChange} required min="6" />
                                    </div>

                                    <div style={{ textAlign: 'center', color: 'red', width: '100%' }}>
                                        <label>{messageError}</label>
                                    </div>

                                    <button type="submit" style={{ fontSize: '18px', fontWeight: 'bold' }} className="btn text-capitalize secondary-background rounded-pill primary-color font-weight-bold col-md-3 col-12">Crear cuenta</button>

                                    <div className='col-12 d-flex flex-row justify-content-center mt-5' style={{ textAlign: 'center' }}>
                                        <div className='col-3 col-md-5'><hr /></div>
                                        <div className='col-5 col-md-2'><span style={{ fontSize: '15px', color: 'gray' }}>O registrate con</span></div>
                                        <div className='col-3 col-md-5'><hr /></div>
                                    </div>

                                    <div className='col-12 d-flex flex-row justify-content-center align-content-center mt-4' style={{ gap: '50px' }}>
                                        <span style={{ backgroundColor: '#24393E', width: '50px', height: '50px' }} className='rounded-circle d-flex align-items-center justify-content-center'><Facebook size={25} color='white' /></span>
                                    </div>

                                    <div className='col-12 d-flex flex-row justify-content-center mt-5 primary-color font-weight-bold'>
                                        ¿Ya tienes cuenta? &nbsp;&nbsp; <u><Link className='primary-color' style={{ fontSize: '17px' }} to="/ingresar">INGRESA AQUI</Link></u>
                                    </div>

                                    <div style={{ textAlign: 'center', width: '100%', marginTop: '1rem' }}>
                                        {
                                            configAccount.config?.facebook_app_id &&
                                            <FacebookLogin
                                                appId={configAccount.config?.facebook_app_id}
                                                autoLoad={false}
                                                fields="name,email"
                                                callback={responseFacebook}
                                                cssClass="facebook-button-class"
                                                icon="fa-facebook"
                                                textButton="Registrar con Facebook"
                                                disableMobileRedirect={true} />
                                        }
                                    </div>
                                </form>
                                :
                                <form className="signup-form" onSubmit={simplyRegister}>
                                    <div className="form-group">
                                        <input type="text" className="form-control  rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf007;&nbsp;&nbsp;Nombre completo" name="name"
                                            value={form.name} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf095;&nbsp;&nbsp;Celular" name="mobile"
                                            value={form.mobile} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf084;&nbsp;&nbsp;Pin" name="pin"
                                            value={form.pin} onChange={handleChange} required />
                                    </div>


                                    <button type="submit" style={{ fontSize: '18px', fontWeight: 'bold' }} className="btn text-capitalize secondary-background rounded-pill primary-color font-weight-bold">Crear cuenta</button>

                                    <div style={{ textAlign: 'center', color: 'red', width: '100%' }}>
                                        <label>{messageError}</label>
                                    </div>

                                    <div className='col-12 d-flex flex-row justify-content-center mt-5' style={{ textAlign: 'center' }}>
                                        <div className='col-3 col-md-5'><hr /></div>
                                        <div className='col-5 col-md-2'><span style={{ fontSize: '15px', color: 'gray' }}>O registrate con</span></div>
                                        <div className='col-3 col-md-5'><hr /></div>
                                    </div>

                                    <div style={{ textAlign: 'center', width: '100%', marginTop: '1rem' }}>
                                        {
                                            configAccount.config?.facebook_app_id &&
                                            <FacebookLogin
                                                appId={configAccount.config?.facebook_app_id}
                                                autoLoad={false}
                                                fields="name,email"
                                                callback={responseFacebook}
                                                cssClass="facebook-button-class rounded-pill"
                                                icon="fa-facebook"
                                                textButton="Registrar con Facebook"
                                                disableMobileRedirect={true}
                                                version="20.0" />
                                        }
                                    </div>

                                    <div className='col-12 d-flex flex-row justify-content-center mt-5 primary-color font-weight-bold'>
                                        ¿Ya tienes cuenta? &nbsp;&nbsp; <u><Link className='primary-color' style={{ fontSize: '17px' }} to="/ingresar">INGRESA AQUI</Link></u>
                                    </div>
                                </form>
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment >
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    setLocalStorage: (data) => {
        dispatch(userSetStorageAction(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Register);

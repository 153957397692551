import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

const GoogleMap = ({lat, lng}) => {
    return (
        <Map google={window.google} style={{ width: '100%', height: '500px'}}
            initialCenter={{
                lat,
                lng
            }}>
            <Marker
                position={{ lat, lng}} />
        </Map>
    );
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBRqcabWXM6tmqV36R_x_r_6G4zi4gNU-Y'
})(GoogleMap);
import React from 'react'

const ItemBuySell = ({number, title, description, Icon}) => {
  return (
    <div className='col-6' style={{marginTop: '70px'}}>
        <div className='col-11 d-flex flex-row'>
            <span className='secondary-background rounded' style={{width: '6px', height: '70px', position: 'absolute'}}></span>
            <span className='primary-background rounded ml-3' style={{width: '6px', height: '70px', position: 'absolute', bottom: '15px'}}></span>
            {
                Icon ?? <h1 className='display-4 ml-5 font-weight-bold primary-color'>{number}</h1>
            }
        </div>
        <div className='offset-1 col-10 mt-2'>
            <h4 className='display-6 font-weight-bold primary-color'>{title} </h4>
        </div>
        <div className='offset-1 col-11 mt-3'>
            <p style={{fontSize: '15px'}} className='primary-color'>
            {
                description
            }
            </p>
        </div>
    </div>
  )
}

export default ItemBuySell
import React, { useEffect, useState } from 'react';
import './ForgetPassword.scss';
import Loading from '../loading/Loading';
import { recoverPassword } from '../../services/UserServices';
import { toast } from 'react-toastify';

const ForgetPassword = ({ close }) => {

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState(false)
    const [messageError, setMessageError] = useState(false)

    useEffect(() => {
        toast.configure();
    }, [])

    const handleChange = event => {
        const { value } = event.target;
        setEmail(value);
    }

    const resetPassword = async () => {
        if (email === '') {
            setMessageError('Debe ingresar el email de su cuenta');
            return
        }
        setLoading(true);
        try {
            let response = await recoverPassword({
                email
            });
            setLoading(false);
            setEmail('');
            toast("Se envió una nueva contraseña a su email con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            close();
        } catch (error) {
            setLoading(false);
            toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            close();
        }
    }

    return (
        <React.Fragment>
            <div className="container-modal">
                <div className="content-modal">
                    {loading && <Loading />}
                    <h5 style={{ marginBottom: '1rem' }}>Recuperar contraseña</h5>
                    <i onClick={() => close()} className="fas fa-times-circle"></i>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" placeholder="Ingrese su email" name="email"
                            value={email} onChange={handleChange} />
                    </div>
                    <div style={{ textAlign: 'center', color: 'red' }}>
                        <label>{messageError}</label>
                    </div>
                    <button onClick={resetPassword} type="button" className="btn rounded-pill primary-background text-white w-100">Confirmar</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ForgetPassword;
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/footer/Footer';
import ProductImage from '../../components/product-details/ProductImage';
import ProductContent from '../../components/product-details/ProductContent';
import { getLot } from '../../services/LotsServices';
import Loading from '../../components/loading/Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import DetailsTab from '../../components/product-details/DetailsTab';

const LotDetails = (props) => {

    const { configAccount, sessionProps } = props
    const id = props.match.params.id;
    const title = `Lote ${id}`;
    const [lot, setLot] = useState({
        images: [],
        description: '',
        auction: '',
        date_close: '',
        price_base: '',
        id_lot: '',
        manualOffer: 0,
        id_remate: 0,
        timeLeftCancelOffer: 0,
        participations: []
    });
    const [loading, setLoading] = useState(false);
    const [timestamp, setTimestamp] = useState(null);

    useEffect(() => {
        let interval = setInterval(function () {
            setTimestamp(timestamp + 1);
        }, 1000);
        return () => {
            clearInterval(interval)
        }
    }, [timestamp])

    const fetchLot = useCallback(async (interval) => {
        if (!id) return;

        const subscribeSocket = async () => {
            let apiKey = 'NlHYhM4c5lMzRdIcgfD2AwA5OftS2u1155yWDjyq';
            let channelId = 1;
            let piesocket = new WebSocket(`wss://s1370.nyc1.piesocket.com/v3/${channelId}?api_key=${apiKey}&notify_self`);
            piesocket.onmessage = async (message) => {
                let data = JSON.parse(message.data);
                let resLot = data.lot;
                if (resLot && resLot.id_remate === lot.id_remate && resLot.id === lot.id) {
                    setLot(prev => ({
                        ...prev,
                        ...resLot,
                        locationUbic: prev.location || resLot.location || '',
                        manualOffer: prev?.manualOffer > resLot.manualOffer ? prev.manualOffer : resLot.manualOffer,
                        participations: resLot?.participations?.filter(item => item === sessionProps?.account?.id).length > 0 ?? []
                    }))
                }
            }
        }

        if (!interval) {
            setLoading(true);
        }
        const response = await getLot({ id });
        const resLot = response.data.data;
        setLot(prev => ({
            ...prev,
            ...resLot,
            locationUbic: resLot.location || '',
            manualOffer: prev?.manualOffer > resLot.manualOffer ? prev.manualOffer : resLot.manualOffer,
            participations: resLot?.participations?.filter(item => item === sessionProps?.account?.id).length > 0 ?? []
        }))
        setTimestamp(response.data.timestamp);
        subscribeSocket();
        if (!interval) {
            setLoading(false);
        }
    }, [id, sessionProps.account, lot.id, lot.id_remate])

    useEffect(() => {
        fetchLot();
        let interval = setInterval(async () => {
            await fetchLot(true);
        }, 15000);
        return () => {
            clearInterval(interval)
        }
    }, [fetchLot])

    useEffect(() => {
        if (configAccount?.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
    }, [configAccount, title])

    return (
        <React.Fragment>
            <Navbar />
            {loading && <Loading />}
            <section className={'products-details-area col-12 d-flex container ml-md-0 mr-md-0 flex-column-reverse flex-md-row ptb-60'}>
                <div className="col-12 col-md-5 order-md-1 order-2 offset-md-1">
                    <Link className='mb-2' to={`/subastas/${lot.id_remate}${props.match.params.y !== undefined ? '/' + props.match.params.y : ''}`}
                        style={{ cursor: "pointer", textDecoration: 'none', fontSize: '1rem' }}>
                        <i className="fas fa-chevron-left"></i>
                        <span className='primary-color font-weight-bold'> Volver al listado</span>
                    </Link>
                    <span className='d-md-none'><DetailsTab {...lot} /></span>
                    <ProductImage images={lot.images} id={lot.id} />
                    <span className='d-md-none'><ProductContent {...lot} {...props} id={id} timestamp={timestamp} /></span>
                </div>
                <div className='col-12 col-md-5 order-md-3 order-3'>
                    <span className='d-none d-md-block'><DetailsTab {...lot} /></span>
                    <span className='d-none d-md-block'><ProductContent {...lot} {...props} id={id} timestamp={timestamp} /></span>
                </div>
            </section>
            <Footer />
        </React.Fragment >
    );

}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LotDetails);
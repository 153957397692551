import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './CarouselPhotos.scss';
import { Constants } from '../../Constants';
import { connect } from 'react-redux';

const CarouselPhotos = ({ photos, configAccount, sessionProps }) => {
    const scrollToAuctionsList = () => {
        document.getElementById('auctionsInProgress').scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <Carousel indicators={false} className="carousel-home">
            {
                photos.map((photo, index) =>
                    <Carousel.Item key={index} interval={5000}>
                        {photo.id_remate !== null ? (
                            <Link to={`/subastas/${photo.id_remate}`}>
                                <img
                                    className='d-block w-100'
                                    src={`${Constants.BASE_URL}${photo.image}`}
                                    alt=""
                                />
                            </Link>
                        ) : photo.is_principal ? (
                            sessionProps?.account === null ?
                                <Link to={`/ingresar`}>
                                    <img
                                        className='d-block w-100'
                                        src={`${Constants.BASE_URL}${photo.image}`}
                                        alt=""
                                    />
                                    <div className='details-for-banner'>
                                        <div>
                                            {
                                                window.socket_io_customer === 'subastas_ya' ?
                                                    <img alt='logo' src={Constants.BASE_URL + configAccount?.config?.logo_admin} />
                                                    :
                                                    <img alt='logo' src={Constants.BASE_URL + configAccount?.config?.logo_web} />
                                            }
                                            <label className='text-white text-center'>{photo.text_principal}</label>
                                            <button className='primary-background primary-color rounded-pill font-weight-bold'>Ingresar</button>
                                        </div>
                                    </div>
                                </Link>
                                :
                                <>
                                    <img
                                        className='d-block w-100'
                                        src={`${Constants.BASE_URL}${photo.image}`}
                                        alt=""
                                    />
                                    <div className='details-for-banner'>
                                        <div>
                                            {
                                                window.socket_io_customer === 'subastas_ya' ?
                                                    <img alt='logo' src={Constants.BASE_URL + configAccount?.config?.logo_admin} />
                                                    :
                                                    <img alt='logo' src={Constants.BASE_URL + configAccount?.config?.logo_web} />
                                            }
                                            <label className='text-white text-center'>{photo.text_principal}</label>
                                            <button onClick={() => scrollToAuctionsList()}
                                                className='primary-background primary-color rounded-pill font-weight-bold'>Participar</button>
                                        </div>
                                    </div>
                                </>
                        ) : (
                            <img
                                className='d-block w-100'
                                src={`${Constants.BASE_URL}${photo.image}`}
                                alt=""
                            />
                        )}
                    </Carousel.Item>
                )
            }
        </Carousel>
    );
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer,
    sessionProps: state.userReducer
})

export default connect(mapStateToProps)(CarouselPhotos);

import React, { useEffect, useState } from 'react';
import './CompleteData.scss';
import Loading from '../loading/Loading';
import { updateMyAccount } from '../../services/UserServices';
import { toast } from 'react-toastify';
import { setPhoneInLocalStorage, userSetStorageAction } from '../../redux/actions/UserActions';
import { connect } from 'react-redux';

const CompleteData = ({ sessionProps, setPhone, logout, history, setLocalStorage }) => {

    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        phone: '',
        telephone: ''
    });
    const [messageError, setMessageError] = useState('');

    useEffect(() => {
        toast.configure();
    }, [])

    const handleChange = event => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    }

    const saveAccount = async (e) => {
        e.preventDefault();
        setLoading(true);
        let account = {
            token: sessionProps.account.token,
            phone: form.phone,
            telephone: form.telephone,
        };
        try {
            let response = await updateMyAccount(account);
            toast("Su cuenta fue actualizada con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            setPhone(response.data.phone);
            setLocalStorage(response.data.data);
            setLoading(false);
            setMessageError('');
        } catch (error) {
            setLoading(false);
            if (error.response?.status === 401) {
                logout();
                history.push('/ingresar');
            }
            else {
                let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                setMessageError(msg);
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    return (
        <React.Fragment>
            <div className="container-modal">
                <div className="content-modal">
                    {loading && <Loading />}
                    <h5 style={{ marginBottom: '1rem' }}>Para continuar complete sus datos de contacto</h5>
                    <form className="signup-form" onSubmit={saveAccount}>
                        <div className="form-group">
                            <label>Celular <b style={{ color: 'red' }}>*</b></label>
                            <input type="text" className="form-control" placeholder="Ingrese su celular" name="phone"
                                value={form.phone} onChange={handleChange} required />
                        </div>
                        <button type="submit" className="btn button-with-degrade w-100">Confirmar</button>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
});


const mapDispatchToProps = (dispatch) => ({
    setPhone: () => {
        dispatch(setPhoneInLocalStorage(dispatch));
    },
    setLocalStorage: (data) => {
        dispatch(userSetStorageAction(data))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteData);
import axios from 'axios';
import { Constants } from '../Constants';

export const getLot = async (data) => {
    try {
        return await axios.get(`${Constants.BASE_URL}public/lots/${data.id}`);
    } catch (error) {
        throw error;
    }
}

export const addToFavoriteService = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}myaccount/addToFavorite`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}
import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/footer/Footer';
import { getPageWayToPay } from '../../services/UserServices';
import Loading from '../../components/loading/Loading';
import { connect } from 'react-redux';

const title = 'Forma de pago';

const WayToPay = ({configAccount}) => {

    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [head, setHead] = useState('')

    useEffect(() => {
        const fetchTerms = async () => {
            try {
                setLoading(true)
                let response = await getPageWayToPay();
                let { head, items } = response.data.data;
                setHead(head.dynamic_seccion)
                setItems(items)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        if (configAccount?.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
        fetchTerms()
    }, [configAccount])

    return (
        <React.Fragment>
            <Navbar />
            {loading && <Loading />}
            <section className="faq-area ptb-60">
                <div className="container">
                    <div className="section-title text-left">
                        <h3>Forma de pago</h3>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: head }} className="description-inner-html" />
                    <div className="faq-accordion">
                        <ul className="accordion">
                            <Accordion allowMultipleExpanded={true}>
                                {
                                    items.map(item =>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    {item.title}
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    )
                                }
                            </Accordion>
                        </ul>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment >
    );
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer
});

export default connect(mapStateToProps, null)(WayToPay);
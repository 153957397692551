import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom';
import { getAuctionInProgress } from '../../services/AuctionServices';
import './MegaMenu.scss';
import CompleteData from '../modals/CompleteData';
import { QrCode, Envelope, Telephone } from "react-bootstrap-icons";
import logo from "../../assets/images/logo.png"
import { XLg, Facebook, Twitter, Instagram, Linkedin } from "react-bootstrap-icons"
import { Constants } from '../../Constants';

const MegaMenu = (props) => {
    const { sessionProps, configAccount } = props
    const [collapsed, setCollapsed] = useState(false)
    const [itemSelect, setItemSelect] = useState(1)
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0);
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });

        var str = window.location.pathname.split("/");
        switch (str[1]) {
            case '':
            case 'inicio':
                setItemSelect(1);
                break;
            case 'subastas':
            case 'lotes':
                setItemSelect(2);
                break;
            case 'ayuda':
                setItemSelect(3);
                break;
            case 'contacto':
                setItemSelect(4);
                break;
            case 'forma-de-pago':
                setItemSelect(5);
                break;
            case 'comprar':
                setItemSelect(6);
                break;
            case 'vender':
                setItemSelect(7);
                break;
            case 'terminos-condiciones':
                setItemSelect(8);
                break;
            default:
                setItemSelect(1);
                break;
        }
    }, [itemSelect])


    const toggleNavbar = () => setCollapsed(!collapsed);

    const classOne = !collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    const moveToDiv = () => {

        if (sessionProps?.account?.id) return;

        // if i am in the home page scroll but if i am in another page go to home and scroll
        if (window.location.pathname === '/') {
            const element = document.getElementById('access_code');
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            setCollapsed(false)
        }
        else {
            history.push('/')
            setTimeout(() => {
                const element = document.getElementById('access_code');
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 500)
            setCollapsed(false)
        }
    }

    return (
        <React.Fragment>
            {
                sessionProps.account !== null && (!sessionProps.account.phone || sessionProps.account.phone === null || sessionProps.account.phone === '') && (
                    <CompleteData props={props} />
                )
            }
            <div>
                <div className='primary-background d-none d-md-flex'>
                    <div className='container primary-menu'>
                        <div className='left'>
                            {
                                configAccount.config?.whatsapp &&
                                <a href={`tel://${configAccount.config.whatsapp}`}>
                                    <span className='text-white d-flex' style={{ fontSize: 13 }}>
                                        <span style={{ backgroundColor: 'white', width: '19px', height: '19px', borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginRight: '5px' }}><Telephone size={10} color="black" /></span> {configAccount.config.whatsapp}
                                    </span>
                                </a>
                            }
                            {
                                configAccount.config?.contact_email &&
                                <a href={`mailto:${configAccount.config.contact_email}`}>
                                    <span className='text-white d-flex' style={{ fontSize: 13 }}>
                                        <span style={{ backgroundColor: 'white', width: '19px', height: '19px', borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginRight: '5px' }}><Envelope color="black" /></span> {configAccount.config.contact_email}
                                    </span>
                                </a>
                            }
                        </div>

                        {
                            sessionProps.account === null ?
                                <div onClick={moveToDiv} className='right code-access-cursor'>
                                    <p className='text-white' style={{ fontSize: 15 }}>
                                        <QrCode color='white' /> Código de acceso
                                    </p>
                                </div> : null
                        }
                    </div>
                </div>
                <div className='secondary-background d-flex d-md-none'>
                    <a href="/" className='m-auto'>
                        <img
                            src={configAccount?.config?.logo_web ? Constants.BASE_URL + configAccount.config.logo_web : logo}
                            alt={'logo'}
                            className='logo' style={{ width: '160px', height: 'auto' }}
                        >
                        </img>
                    </a>
                </div>
                <div className='d-flex d-md-none separator' />
                <div className='d-flex d-md-none secondary-background'>
                    <div className='col-12 text-center d-flex'>
                        {
                            sessionProps.account === null ?
                                <div className="enter-button rounded-pill">
                                    <Link style={{ color: 'white', fontWeight: 'bold' }} to="/ingresar">
                                        Ingresar
                                    </Link>
                                </div>
                                :
                                <div className="enter-button rounded-pill">
                                    <Link to="/mi-cuenta" style={{ color: 'white', fontWeight: 'bold', fontSize: '11px' }}>
                                        Hola, {sessionProps.account.name}
                                    </Link>
                                </div>
                        }
                        <button
                            onClick={toggleNavbar}
                            className={`${classTwo} ml-auto`}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            href="#navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon">
                                <i className="fas fa-bars" style={{ color: '#000', fontSize: '26px' }}></i>
                            </span>
                        </button>
                    </div>
                </div>
                {
                    collapsed &&
                    <header role="banner" className={`${classOne} navbar navbar-fixed-top navbar-inverse collapse side-collapse-container ${collapsed ? 'out' : ''}  `} id="collapseExample">
                        <div className="container">
                            <div className="navbar-inverse side-collapse">
                                <div className='d-flex flex-row'>
                                    <div className='col-10'>
                                        <nav role="navigation" className="navbar-collapse">
                                            <ul className="navbar-nav flex-column d-md-none mt-5" style={{ gap: '15px', fontSize: '12px', textAlign: 'left' }}>
                                                <li style={{ fontSize: '14px' }} className="nav-item p-relative d-flex align-items-center">
                                                    <Link to='/' className={`nav-link text-white ${itemSelect === 1 && 'active'}`}>
                                                        Inicio
                                                    </Link>
                                                </li>

                                                <li style={{ fontSize: '14px' }} className="nav-item p-relative d-flex align-items-center">
                                                    <Link to='/comprar' className={`text-white nav-link ${itemSelect === 6 && 'active'}`}>
                                                        Comprar
                                                    </Link>
                                                </li>

                                                {/*<li style={{ fontSize: '14px' }} className="nav-item p-relative d-flex align-items-center">
                                                    <Link to='/vender' className={`text-white nav-link ${itemSelect === 7 && 'active'}`}>
                                                        Vender
                                                    </Link>
                                                </li>

                                                <li style={{ fontSize: '14px' }} className="nav-item p-relative d-flex align-items-center">
                                                    <Link to='/ayuda' className={`text-white nav-link ${itemSelect === 3 && 'active'}`}>
                                                        FAQ
                                                    </Link>
                                                </li>*/}

                                                <li style={{ fontSize: '14px' }} className="nav-item p-relative d-flex align-items-center">
                                                    <Link to='/terminos-condiciones' className={`text-white nav-link ${itemSelect === 3 && 'active'}`}>
                                                        Términos y condiciones
                                                    </Link>
                                                </li>

                                                <li style={{ fontSize: '14px' }} className="nav-item p-relative d-flex align-items-center">
                                                    <Link to='/contacto' className={`text-white nav-link ${itemSelect === 4 && 'active'}`}>
                                                        Contacto
                                                    </Link>
                                                </li>

                                                {
                                                    sessionProps.account === null ?
                                                        <li onClick={moveToDiv} style={{ fontSize: '14px' }} className="nav-item p-relative d-flex align-items-center text-white mt-2 code-access-cursor">
                                                            &nbsp;<QrCode color='white' /> Código de acceso
                                                        </li> : null
                                                }
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className='col-1 p-2'>
                                        <XLg onClick={() => setCollapsed(false)} className="secondary-color" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                }
                <div className={`secondary-background ${!window.style_config?.header?.force_css ? 'navbar-with-image' : 'navbar-without-image'}` + ' mega-menu-navbar side-collapse-container pb-1 pb-sm-0'}>
                    <div id="navbar" className="mega-menu-navbar container">
                        <div className="mega-menu-navbar">
                            <nav className="d-none d-md-flex flex-row mega-menu-navbar align-items-center pl-0">
                                <div className=''>
                                    <a href="/">
                                        <img
                                            src={configAccount?.config?.logo_web ? Constants.BASE_URL + configAccount.config.logo_web : logo}
                                            alt={'logo'}
                                            className='logo' style={{ width: '160px', height: 'auto' }}
                                        >
                                        </img>
                                    </a>
                                </div>

                                <div className='right'>
                                    <ul className="navbar-nav flex-row justify-content-end">
                                        <li className="nav-item p-relative d-flex align-items-center">
                                            <Link to='/' className={`nav-link primary-color ${itemSelect === 1 && 'active'}`}>
                                                Inicio
                                            </Link>
                                        </li>

                                        <li className="nav-item p-relative d-flex align-items-center">
                                            <Link to='/comprar' className={`primary-color nav-link ${itemSelect === 6 && 'active'}`}>
                                                Comprar
                                            </Link>
                                        </li>

                                        {/*<li className="nav-item p-relative d-flex align-items-center">
                                            <Link to='/vender' className={`primary-color nav-link ${itemSelect === 7 && 'active'}`}>
                                                Vender
                                            </Link>
                                        </li>

                                        <li className="nav-item p-relative d-flex align-items-center">
                                            <Link to='/ayuda' className={`primary-color nav-link ${itemSelect === 3 && 'active'}`}>
                                                FAQ
                                            </Link>
                                        </li>*/}

                                        <li className="nav-item p-relative d-flex align-items-center">
                                            <Link to='/terminos-condiciones' className={`primary-color nav-link ${itemSelect === 8 && 'active'}`}>
                                                Términos y condiciones
                                            </Link>
                                        </li>

                                        <li className="nav-item p-relative d-flex align-items-center">
                                            <Link to='/contacto' className={`primary-color nav-link ${itemSelect === 4 && 'active'}`}>
                                                Contacto
                                            </Link>
                                        </li>

                                        <li className="nav-item p-relative d-flex align-items-center">
                                            {
                                                sessionProps.account === null ?
                                                    <div className="enter-button rounded-pill col-10 col-md-12">
                                                        <Link style={{ color: 'white', fontWeight: 'bold' }} to="/ingresar">
                                                            Ingresar
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div className="enter-button rounded-pill col-10 col-md-12">
                                                        <Link to="/mi-cuenta" style={{ color: 'white', fontWeight: 'bold', fontSize: '11px' }}>
                                                            Hola, {sessionProps.account.name}
                                                        </Link>
                                                    </div>
                                            }
                                            <button
                                                onClick={toggleNavbar}
                                                className={`${classTwo} d-md-none col-2`}
                                                type="button"
                                                data-toggle="collapse"
                                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                                href="#navbarSupportedContent"
                                                aria-expanded="false"
                                                aria-label="Toggle navigation"
                                            >
                                                <span className="navbar-toggler-icon">
                                                    <i className="fas fa-bars" style={{ color: '#000', fontSize: '26px' }}></i>
                                                </span>
                                            </button>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

export default connect(mapStateToProps)(MegaMenu);

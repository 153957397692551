import React, { useEffect } from 'react';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/footer/Footer';
import { connect } from 'react-redux';
import ItemBuySell from '../../components/item_buy_sell/Item_buy_sell';
import { CashCoin, ArrowRepeat } from 'react-bootstrap-icons';

let title = 'Vender';

const items = {
    0: [
        {
            title: 'Crearte un usuario',
            description: 'Dirígite a la opción Iniciar sesión y selecciona REGÍSTRATE AQUÍ. En esta sección se te pedirán algunos datos personales y tendrás que crear una contraseña personal que te permitirá entrar regularmente a tu cuenta.'
        },
        {
            title: 'Realiza una subasta',
            description: 'Una vez activa una subasta podrás encontrar objetos de tu interés y comenzar a generar tus ofertas. El mejor postor al momento de finalizar la subasta será el comprador de dicho lote.'
        },
    ],
    1: [
        {
            title: 'Cierre de oferta por lote',
            description: 'Al iniciar el proceso de cierre en la hora indicada, comenzarán a cerrarse un conjunto de lotes cada 3 minutos y así sucesivamente hasta finalizar la totalidad de los lotes publicados.'
        },
        {
            title: 'Excepción de cierre',
            description: 'Cuando los lotes estén por cerrarse, la cuenta regresiva determina el cierre, salvo si hay una oferta en el último minuto de dicha cuenta regresiva, se entenderá el tiempo por un minuto más.'
        }
    ],
    2: [
        {
            icon: <CashCoin className='ml-5 font-weight-bold primary-color' size={70} />,
            title: 'Pago en efectivo',
            description: 'Nuestro local: San José de Mayo. Horarios: lunes a viernes de 14 a 18 hs'
        },
        {
            icon: <ArrowRepeat className='ml-5 font-weight-bold primary-color' size={70} />,
            title: 'Transferencia / Depósito',
            description: 'Aceptamos pagos a nuestra cuenta en el Banco República Oriental del Uruguay (BROU).'
        }
    ]
}

const Buy = ({configAccount}) => {

    useEffect(() => {
        if (configAccount.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
    }, [configAccount])

    return (
        <React.Fragment>
            <Navbar />
            <section className="faq-area pt-60">
                <div className="container">
                    <div className="section-title mb-5">
                        <div className="text-center d-flex flex-column position-relative justify-content-center align-items-center">
                            <h4 className='display-5 font-weight-bold'>VENDER</h4>
                            <hr className='col-3 yellow-separator position-absolute rounded-pill' style={{top:'30px'}} />
                        </div>
                    </div>
                    <h4 className="primary-color font-weight-bold" >¿Cómo puedo registrarse para crear subastas online?</h4>
                    <div className='row mb-5'>
                    {
                        items[0]?.map((item, index) => <ItemBuySell description={item.description} title={item.title} number={"0"+(index + 1)} key={index} />)
                    }
                    </div>
                    <br/><br/>
                    <h4 className="primary-color font-weight-bold" >¿Cómo funciona el proceso de venta y cierre?</h4>
                    <div className='row'>
                    {
                        items[1]?.map((item, index) => <ItemBuySell description={item.description} title={item.title} number={"0"+(index + 1)} key={index} />)
                    }
                    </div>
                    <br/><br/><br/><br/>
                    <h4 className="primary-color font-weight-bold" >¿Cómo se realiza el cobro de la mercadería vendida?</h4>
                    <div className='row'>
                    {
                        items[2]?.map((item, index) => <ItemBuySell Icon={item.icon ?? ''} description={item.description} title={item.title} number={"0"+(index + 1)} key={index} />)
                    }
                    </div>
                    <br/><br/><br/><br/>
                </div>
                <div className='row d-flex flex-row align-items-center justify-content-around p-5' style={{backgroundColor: '#E5E5E5'}}>
                        <div className='offset-1 col-6'>
                            <h4 className='primary-color font-weight-bold'>¿Tienes alguna pregunta?</h4>
                            <p className='primary-color'>¡Estamos encantados de ayudarte! Por favor, si no encuentras respuesta a tu pregunta, no dudes en escribirnos y nos contactaremos contigo a la brevedad posible.</p>
                        </div>
                        <div className='col-3'>
                            <button className='w-100 secondary-background rounded-pill font-weight-bold p-2 border-0'>
                                <a className='primary-color' href="tel://092220441">Comunícate al 092 220 441</a>
                            </button>
                        </div>
                    </div>
            </section>
            <Footer />
        </React.Fragment >
    );
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer
});

export default connect(mapStateToProps, null)(Buy);
import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import AuctionCard from '../auctions/AuctionCard';
import './styles.scss'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
}

const AcutionsList = (props) => {

    const { inProgress, title, row, cant, col, small, text_color, next, carousel } = props

    useEffect(() => {
        moment.locale('es');
        window.scrollTo(0, 0);
    }, [])

    return (
        <section className={`p-4 p-md-0 pt-md-5 category-boxes-area ${row}`} style={{ marginTop: '5rem', paddingTop: '20px' }} id="auctionsInProgress">

            <div className="section-title" style={{ marginBottom: '2rem' }}>
                <hr className='yellow-separator w-25 rounded-pill mb-3' />
                <h4 className={`${inProgress ? 'auction-in-proccess' : ''} font-weight-bold ${text_color}`} style={{ margin: 'auto' }}><span className="dot"></span>{title}</h4>
            </div>

            {
                carousel ? (
                    <div>
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlaySpeed={5000}
                            showDots={false}
                            arrows={cant.length}
                            autoPlay={true}
                        >
                            {cant.map((item, index) =>
                                <AuctionCard carousel key={index} col={col} small={small} item={item} next={next} />
                            )}
                        </Carousel>
                    </div>
                ) : (
                    <div className={`justify-content-center items items-flex`}>
                        {
                            cant.map(item => <AuctionCard col={col} small={small} item={item} next={next} />)
                        }
                    </div>
                )
            }
        </section>
    );
}

export default AcutionsList;
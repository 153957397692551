import React from 'react';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/footer/Footer';
import ProductsCollectionsTwo from '../../components/collections-styles/ProductsCollectionsTwo';

const AuctionDetails = (props) => (
    <React.Fragment>
        <Navbar />
        <ProductsCollectionsTwo {...props} />
        <Footer />
    </React.Fragment>
);

export default AuctionDetails;

import React from 'react';
import ImageGallery from 'react-image-gallery';
import { Constants } from '../../Constants';
import './ProductImage.scss';
import expand from '../../assets/images/lupa+.svg'
import reduce from '../../assets/images/lupa-.svg'

const ProductImage = (props) => {
    return (
        <>
            <div className="d-none d-md-block products-page-gallery">
                <ImageGallery thumbnailPosition={'left'} items={props.images.map(item =>
                    ({ id: item.id, original: item.image, thumbnail: item.image_thumb }))}
                    showPlayButton={false} renderFullscreenButton={(onClick, isFullscreen) => {
                        return (
                            <div className="image-gallery-icon-fullscreen" onClick={onClick}>
                                {isFullscreen ?
                                    <img src={reduce} alt='product' data-toggle="tooltip" data-placement="top" title="Salir pantalla completa" />
                                    : <img src={expand} alt='product' data-toggle="tooltip" data-placement="top" title="Ver en pantalla completa" />
                                }
                            </div>
                        );
                    }}
                />
            </div>
            <div className="d-md-none products-page-gallery">
                <ImageGallery thumbnailPosition={'bottom'} items={props.images.map(item =>
                    ({ id: item.id, original: item.image, thumbnail: item.image_thumb }))}
                    showPlayButton={false} renderFullscreenButton={(onClick, isFullscreen) => {
                        return (
                            <div className="image-gallery-icon-fullscreen" onClick={onClick}>
                                {isFullscreen ?
                                    <img src={reduce} alt='product' data-toggle="tooltip" data-placement="top" title="Salir pantalla completa" />
                                    : <img src={expand} alt='product' data-toggle="tooltip" data-placement="top" title="Ver en pantalla completa" />
                                }
                            </div>
                        );
                    }}
                />
            </div>
        </>
    );
}

export default ProductImage;

import moment from 'moment';
import React from 'react';

const DetailsTab = ({ date_close, auction, title }) => {

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    if (!auction) return null;

    return (
        <div className="tab products-details-tab">
            <h4>{title}</h4>

            <ul className="product-info">
                <li><span className='text-muted'>Subasta:</span> {auction?.title}</li>
                <li><span className='text-muted'>Cierre:</span> {
                    date_close === '2050-01-01 00:00:00' ?
                        'Cierre presencial' :
                        ` ${capitalize(moment(date_close).format('dddd D'))} de 
                        ${capitalize(moment(date_close).format('MMMM'))} - ${moment(date_close).format('HH:mm')} hs.`}</li>
            </ul>

            <hr className='mt-4 mb-4' />
        </div>
    );
}

export default DetailsTab;

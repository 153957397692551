import React, { useEffect, useState } from 'react';
import Navbar from '../components/Layout/Navbar';
import BannerSlider from '../components/home/BannerSlider';
import AcutionsList from '../components/home/AcutionsList';
import Footer from '../components/Layout/footer/Footer';
import { getAllAuctions } from '../services/AuctionServices';
import Loading from '../components/loading/Loading';
import { Constants } from '../Constants';
import { toast } from 'react-toastify';
import './Home.scss';
import { connect } from 'react-redux';
import { userLoginWithCode } from '../services/UserServices';
import { userSetStorageAction } from '../redux/actions/UserActions';
import ShowMessage from '../components/modals/ShowMessage';
import groupImg from '../assets/images/Group-ingreso.png';

const title = 'Inicio';

const Home = ({ configAccount, setLocalStorage, sessionProps }) => {

    const [inProgress, setInProgress] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [pending, setPending] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loginCode, setLoginCode] = useState('');
    const [messageErrorOffer, setMessageErrorOffer] = useState(false);
    const [color, setColor] = useState('red');

    const fetchAuctions = async (showSpinner = true) => {
        showSpinner && setLoading(true);
        try {
            let response = await getAllAuctions();
            if (showSpinner && response.data.data.version !== Constants.VERSION) {
                toast.error("La versión de la aplicación ha sido actualizada, refresque la página por favor", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
            setInProgress(response.data.data.inProgress);
            setCompleted(response.data.data.completed);
            setPending(response.data.data.pending);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        toast.configure();
        if (configAccount.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
        toast.configure();
        fetchAuctions();
        let interval = setInterval(() => {
            fetchAuctions(false);
        }, 5000);
        return () => clearInterval(interval);
    }, [configAccount])


    const handleChange = event => {
        const { value } = event.target;
        setLoginCode(value);
    }

    const loginWithCode = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            let response = await userLoginWithCode({
                loginCode
            });
            setLoading(false);
            setLocalStorage(response.data.data);
        } catch (error) {
            setLoading(false);
            let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            if (msg === 'El sistema no le permite realizar nuevas compras porque tiene facturas pendientes de pago. Contáctese con nosotros para regularizar el pago.') {
                setMessageErrorOffer(msg);
                setColor('red');
            } else {
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    const closeModalMessageErrorOffer = async () => {
        setMessageErrorOffer(false);
    }

    return (
        <React.Fragment>
            <Navbar />
            {loading && <Loading />}
            {messageErrorOffer && <ShowMessage msg={messageErrorOffer}
                close={() => closeModalMessageErrorOffer()} color={color} />}
            <BannerSlider />
            {
                inProgress.length > 0 &&
                <AcutionsList title="SUBASTAS EN PROCESO" carousel={true} cant={inProgress} col="col-12 col-md-3" text_color={'primary-color'} inProgress={true} />
            }
            {
                pending.length > 0 &&
                <AcutionsList title="PRÓXIMAS SUBASTAS" cant={pending} col="col-12 col-md-4" text_color={'primary-color'} next={true} />
            }
            {
                completed.length > 0 &&
                <AcutionsList title="SUBASTAS FINALIZADAS" cant={completed} col="col-12 col-md-4" text_color={'text-white'} row="primary-background" small={true} />
            }
            {
                sessionProps.account === null &&
                <React.Fragment>
                    <div id="access_code" className="align-items-center p-4 p-md-5" style={{ marginTop: '1rem', backgroundColor: '#E5E5E5' }}>
                        <div className="d-flex flex-column flex-md-row text-center">
                            <div className='col-md-5 col-12 offset-md-1 mb-5 mb-md-0'>
                                <img src={groupImg} alt="imagen group"></img>
                            </div>
                            <div className='col-md-4 p-0 col-12 offset-md-1 d-flex justify-content-center align-items-center'>
                                <span style={{ borderRadius: '4%' }} className='primary-background p-5 text-white'>
                                    <p className='text-white' style={{ width: '100%', marginBottom: '0.2rem', fontSize: '18px', fontWeight: 'bold' }}>
                                        Si esta registrado y tiene problemas para ingresar, solicite un código de acceso directo
                                    </p>
                                    <br />
                                    <p className='text-white mb-4 font-weight-bold'>
                                        ¿Ya tienes un código de acceso?
                                    </p>
                                    <input className='form-control rounded-pill' style={{ width: '100%', textAlign: 'center' }} placeholder="Ingrese su código de acceso" name="loginCode"
                                        value={loginCode} onChange={handleChange} required />
                                    <button type="button" onClick={loginWithCode} style={{ width: '100%', marginTop: '0.5rem', height: '45px' }} className="btn secondary-background rounded-pill font-weight-bold">Ingresar</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
            <Footer />
        </React.Fragment >
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    setLocalStorage: (data) => {
        dispatch(userSetStorageAction(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './ProductsCollectionsTwo.scss';
import { getAuction } from '../../services/AuctionServices';
import Loading from '../loading/Loading';
import moment from 'moment';
import 'moment/locale/es';
import { Constants } from '../../Constants';
import { connect } from 'react-redux'
import { addOffer, deleteOffer } from '../../services/OffersServices';
import { toast } from 'react-toastify';
import { addToFavoriteService } from '../../services/LotsServices';
import 'react-toastify/dist/ReactToastify.css';
import { logoutAction } from '../../redux/actions/UserActions';
import { confirmAlert } from 'react-confirm-alert';
import { Carousel } from 'react-bootstrap';
import ShowMessage from '../modals/ShowMessage';
import { Calendar2, CheckCircle, DashCircle } from 'react-bootstrap-icons';

class ProductsCollectionsTwo extends Component {
    state = {
        id: this.props.match.params.id,
        auction: {
            title: '',
            description: '',
            dateDescription: '',
            lots: [],
            secondsStart: '',
            minutesStart: '',
            pending: false,
            catalog: '',
            isPreOferta: true
        },
        loading: false,
        intervalAuction: null,
        intervalCountDown: null,
        intervalGetDateTime: null,
        intervalCountDownStart: null,
        closingLots: false,
        positionY: 0,
        title: `Subasta #${this.props.match.params.id}`,
        timestamp: null,
        messageErrorOffer: false,
        color: 'black',
        tab: 'all',
    }

    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        toast.configure();
        moment.locale('es');
        await this.fetchAuction();
        let interval = setInterval(() => {
            this.fetchAuction(false, 1);
        }, 15000);
        await this.setState({
            intervalAuction: interval
        });
        this.subscribeSocket();
        document.addEventListener("scroll", () => {
            this.setState({
                positionY: window.pageYOffset
            })
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
        }
    }

    componentWillReceiveProps = async (newProps) => {
        if (this.state.id !== newProps.match.params.id) {
            await this.setState({
                id: newProps.match.params.id
            })
            this.fetchAuction();
        }
    }

    subscribeSocket = async () => {
        let apiKey = 'NlHYhM4c5lMzRdIcgfD2AwA5OftS2u1155yWDjyq';
        let channelId = 1;
        let piesocket = new WebSocket(`wss://s1370.nyc1.piesocket.com/v3/${channelId}?api_key=${apiKey}&notify_self`);
        piesocket.onmessage = async (message) => {
            let data = JSON.parse(message.data);
            let lot = data.lot;
            if (lot && lot.id_remate === this.state.auction.id) {
                let lots = this.state.auction.lots;
                const id_lot = parseFloat(lot.id_lot).toFixed(2);
                if (lots[id_lot].manualOffer > lot.manualOffer && !lots[id_lot].is_delete_offer) {
                    lot.manualOffer = lots[id_lot].manualOffer;
                }
                lot.image_lot = lots[id_lot]?.image_lot;
                lot.image_remision = lots[id_lot]?.image_remision;
                lot.participation = lot.participations.filter(item => item === this.props.sessionProps?.account?.id).length > 0 ? true : null;
                lot.quantitySelect = lots[id_lot].quantitySelect;
                lots[id_lot] = lot;
                await this.setState({
                    auction: {
                        ...this.state.auction,
                        lots: lots
                    },
                    timestamp: data.timestamp ? data.timestamp : this.state.timestamp
                });
            }
        }
    }

    fetchAuction = async (showLoading = true, interval = 0) => {
        showLoading && await this.setState({ loading: true });
        try {
            let token = this.props.sessionProps.account && this.props.sessionProps.account.token ? this.props.sessionProps.account.token : null;
            let response = await getAuction({ id: this.state.id, token: token, interval: interval });
            if (showLoading && response.data.data.version !== Constants.VERSION) {
                toast.error("La versión de la aplicación ha sido actualizada, refresque la página por favor", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
            let lots = response.data.data.lots;
            let __lots = this.state.auction.lots;
            lots.map(item => {
                const id_lot = parseFloat(item.id_lot).toFixed(2);
                if (__lots[id_lot]) {
                    if (__lots[id_lot].manualOffer > item.manualOffer) {
                        item.manualOffer = __lots[id_lot].manualOffer;
                    }
                }
                if (!interval && this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer) {
                    item.quantitySelect = item.offerQty;
                }
                else {
                    item.quantitySelect = __lots[id_lot]?.quantitySelect ?? item.quantity;
                }
                __lots[id_lot] = item;
            });
            await this.setState({
                auction: {
                    ...this.state.auction,
                    ...response.data.data,
                    dateTime: response.data.data.dateTime.date,
                    lots: __lots,
                    status: this.getEstado(response.data.data)
                },
                loading: false,
                timestamp: response.data.timestamp
            });
            if (this.state.auction.starting) {
                this.getCountDown();
            }
            if (this.state.auction.pending) {
                this.getCountDownStart();
            }
            if (showLoading && this.props.match.params.y !== undefined) {
                window.scrollTo(0, this.props.match.params.y);
                this.setState({
                    positionY: this.props.match.params.y
                })
                // elimino el param y de la url
                const { history, location } = this.props;
                const pathParts = location.pathname.split('/');
                // Eliminamos el último segmento de la ruta, que corresponde al parámetro "y"
                pathParts.pop();
                const newPath = pathParts.join('/');
                // Usamos history.replace para actualizar la URL sin recargar la página
                history.replace(newPath);
            }
        } catch (error) {
            await this.setState({ loading: false });
            // if error message es "Remate no encontrado" then redirect to /home
            if (error.response?.data?.message === 'Remate no encontrado') {
                this.props.history.push('/');
            }
        }
    }

    getEstado = (auction) => {
        let dateFrom = auction.date_from;
        let dateTo = auction.date_to;
        let dateTime = auction.dateTime.date;
        if (auction.lots.length > 0) {
            let lots = [...auction.lots];
            lots.sort(function (a, b) {
                if (a.real_date_close > b.real_date_close) return 1;
                if (a.real_date_close < b.real_date_close) return -1;
                return 0;
            });
            dateTo = lots[lots.length - 1].real_date_close;
        }
        if (moment(auction.date_to) > moment(dateTime) && moment(dateFrom) < moment(dateTime)) {
            return 'Subasta en proceso';
        }
        if (moment(dateTo) > moment(dateTime) && moment(dateFrom) < moment(dateTime)) {
            return 'Cerrando subasta';
        }
        if (moment(dateTo) < moment(dateTime) && moment(dateFrom) < moment(dateTime)) {
            return 'Subasta finalizada';
        }
        return '';
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    handleChange = (event, item) => {
        const { name, value } = event.target;
        let valor
        if (this.state.auction.moneySymbol === 'USD' && item.nextOffer <= 100 && value) {
            valor = parseInt(value);
            item.manualOffer = valor;
            return
        }
        else {
            valor = parseInt(Math.round(value / 10) * 10);
        }
        if (valor < item.nextOffer) {
            item.manualOffer = item.nextOffer;
        }
        else {
            item.manualOffer = valor;
        }
    }

    offer = (item, suggestedValue = null) => {
        if (suggestedValue) {
            this._offer(item, suggestedValue)
            return
        }
        let answer = false;
        if (item.nextOffer > 1000) {
            if (item.manualOffer >= item.nextOffer + item.nextOffer * 1.60) {
                answer = true;
            }
        }
        else {
            if (item.manualOffer >= item.nextOffer * 2) {
                answer = true;
            }
        }
        if (answer) {
            confirmAlert({
                title: `¿Esta seguro que desea continuar?`,
                message: `Su oferta de ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.manualOffer)} supera altamente la oferta actual`,
                buttons: [
                    {
                        label: 'Cancelar'
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            this._offer(item)
                        }
                    }
                ]
            });
        }
        else {
            confirmAlert({
                title: ``,
                message: `¿Desea ofertar ${this.state.auction.moneySymbol}${new Intl.NumberFormat("de-DE").format(item.manualOffer)} por el lote ${item.id_lot}?`,
                buttons: [
                    {
                        label: 'Cancelar'
                    },
                    {
                        label: 'Ofertar',
                        onClick: () => {
                            this._offer(item)
                        }
                    }
                ]
            });
        }
    }

    _offer = async (item, suggestedValue = null) => {
        await this.setState({ loading: true });
        try {
            let offer = item.nextOffer
            if (suggestedValue) {
                offer = suggestedValue
            } else if (item.manualOffer > item.nextOffer) {
                offer = item.manualOffer
            } else if (this.state.auction.moneySymbol === 'USD' && item.nextOffer <= 100 && item.manualOffer > item.offer) {
                offer = item.manualOffer
            }
            let response = await addOffer({
                token: this.props.sessionProps.account.token,
                id_lot: item.id,
                offer,
                is_suggested: suggestedValue ? true : false,
                qty: item.quantitySelect || item.quantity
            });
            let lots = this.state.auction.lots;
            let lot = response.data.data.lot;
            const id_lot = parseFloat(item.id_lot).toFixed(2);
            lot.image_lot = lots[id_lot]?.image_lot;
            lot.image_remision = lots[id_lot]?.image_remision;
            lot.quantitySelect = lots[id_lot].quantitySelect;
            lots[id_lot] = lot;
            await this.setState({
                auction: {
                    ...this.state.auction,
                    lots: lots
                },
                loading: false
            });
            let msg = 'Tu oferta ha sido ingresada con éxito';
            if (suggestedValue) {
                msg = 'Tu oferta sugerida ha sido ingresada, queda pendiente de aprobación';
            }
            toast(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error.response?.status === 401) {
                this.props.logout();
                this.props.history.push(`/ingresar/${this.state.id}/${this.state.positionY}/subastas`);
            }
            else {
                let msg = 'No fue posible ingresar tu oferta, reintentar nuevamente';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                if (msg == 'Usted tiene compras pendientes y no hemos podido contactarlo, por favor, comuniquese con nosotros para coordinar el retiro de las compras, gracias.') {
                    await this.setState({
                        messageErrorOffer: msg,
                        color: 'black'
                    });
                }
                else if (msg == 'El sistema no le permite realizar nuevas compras porque tiene facturas pendientes de pago. Contáctese con nosotros para regularizar el pago.') {
                    await this.setState({
                        messageErrorOffer: msg,
                        color: 'red'
                    });
                }
                else {
                    toast.error(msg, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        pauseOnHover: false,
                        duration: 10000
                    });
                }
            }
        }
    }

    getCountDown = async () => {
        if (this.state.intervalCountDown !== null) {
            clearInterval(this.state.intervalCountDown);
        }
        let interval = setInterval(async function () {
            await this.setState({
                timestamp: this.state.timestamp + 1
            })
        }.bind(this), 1000);
        await this.setState({
            intervalCountDown: interval
        });
    }

    _countDown = ({ item }) => {
        if (this.state.timestamp === null || item.timestampClose === null) {
            return null;
        }
        //
        let difference = new Date(item.timestampClose * 1000).getTime() - new Date(this.state.timestamp * 1000).getTime();
        let seconds = Math.floor(difference / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        hours %= 24;
        minutes %= 60;
        seconds %= 60;
        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            if (item.status === Constants.STATUS_IN_PROGRESS) {
                item.timestampClose = null;
                item.countDownVisible = false;
                if (item.bestUserIdOffer !== null) {
                    item.status = Constants.STATUS_PENDING;
                    this.setItem(item);
                } else {
                    item.status = Constants.STATUS_NOT_AUCTIONED;
                    this.setItem(item);
                }
            }
            return null;
        }
        //
        if (days === 0 && hours === 0 && minutes === 0 && seconds <= 5 && seconds > 0) {
            if (item.message !== 'Cerrando lote...') {
                item.message = 'Cerrando lote...';
                this.setItem(item);
            }
            return (
                <React.Fragment>
                    <div className="timeclock">
                        <div>
                            Cerrando lote...
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        if (days === 0 && hours === 0 && (minutes < 2 || minutes === 2 && seconds === 0)) {
            if (item.countDownVisible !== true) {
                item.countDownVisible = true;
                this.setItem(item);
            }
            if (seconds.toString().length === 1) {
                seconds = '0' + seconds;
            }
            if (minutes.toString().length === 1) {
                minutes = '0' + minutes;
            }
            return (
                <React.Fragment>
                    <div className="timeclock">
                        <div>
                            {`00:${minutes}:${seconds}`}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        return null;
    }

    countDown = ({ item }) => {
        let countDown = this._countDown({ item });
        if (countDown !== null) {
            return countDown;
        }
        return (<React.Fragment></React.Fragment>);
    }

    setItem = async (item) => {
        let lots = this.state.auction.lots;
        const id_lot = parseFloat(item.id_lot).toFixed(2);
        lots[id_lot] = item;
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        });
    }

    countDownCancelOffer = ({ item }) => {
        try {
            if (this._countDown({ item }) !== null || this.props.sessionProps.account === null || this.props.sessionProps.account.id !== item.bestUserIdOffer) {
                return (<React.Fragment></React.Fragment>);
            }
            if (this.state.timestamp === null || item.timestampCancelOffer === null) {
                return (<React.Fragment></React.Fragment>);
            }
            //
            let difference = new Date(item.timestampCancelOffer * 1000).getTime() - new Date(this.state.timestamp * 1000).getTime();
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            //
            if (days === -1 && hours === -1 && minutes === -1 && seconds + 30 <= 30 && seconds + 30 >= 0) {
                return (
                    <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                        <button onClick={() => this.deleteOffer(item)} style={{ background: 'transparent', border: 'none', color: 'red', textDecoration: 'underline' }}>
                            {`Cancelar oferta (${seconds + 30})`}
                        </button>
                    </div>
                );
            }
            //
            return (<React.Fragment></React.Fragment>);
        } catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    getCountDownStart = async () => {
        if (this.state.intervalCountDownStart !== null) {
            clearInterval(this.state.intervalCountDownStart);
        }
        let interval = setInterval(function () {
            this.processCountDownStart();
        }.bind(this), 1000);
        await this.setState({
            intervalCountDownStart: interval
        });
    }

    processCountDownStart = () => {
        let auction = this.state.auction;
        var days = null; var hours = null; var minutes = null; var seconds = null;
        if (auction.timeLeft !== null) {
            let { day, hour, minute, second } = auction.timeLeft;
            days = parseInt(day);
            hours = parseInt(hour);
            minutes = parseInt(minute);
            seconds = parseInt(second);
            let pending = true;
            if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
                this.fetchAuction(false);
                pending = false;
                auction.timeLeft = null;
            }
            if (pending) {
                if (seconds === 0) {
                    minutes = minutes - 1;
                    seconds = 59;
                }
                else {
                    seconds = seconds - 1;
                }
                auction.timeLeft.second = seconds.toString().length === 1 ? '0' + seconds.toString() : seconds.toString();
                auction.timeLeft.minute = minutes.toString().length === 1 ? '0' + minutes.toString() : minutes.toString();
            }
            this.setState({
                auction: {
                    ...this.state.auction,
                    pending: pending,
                    timeLeft: auction.timeLeft
                }
            });
        }
    }

    componentWillUnmount = () => {
        this.state.intervalAuction !== null && clearInterval(this.state.intervalAuction);
        this.state.intervalCountDown !== null && clearInterval(this.state.intervalCountDown);
        this.state.intervalGetDateTime !== null && clearInterval(this.state.intervalGetDateTime);
    }

    deleteOffer = (item) => {
        confirmAlert({
            title: `Cancelar oferta`,
            message: `¿Esta seguro que desea cancelar la oferta? `,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deleteOffer(item)
                    }
                }
            ]
        });
    }

    _deleteOffer = async (item) => {
        await this.setState({ loading: true })
        try {
            item.is_delete_offer = true;
            await this.setItem(item);
            let response = await deleteOffer({
                token: this.props.sessionProps.account.token,
                id: item.offerId,
                lot: item.id
            });
            await this.setState({ loading: false });
            toast(`La oferta ha sido cancelada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response?.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    updateUrl = (url) => {
        this.props.history.push(window.location.pathname + '/' + this.state.positionY);
        window.location.href = url;
    }

    addToFavorite = async (item) => {
        await this.setState({ loading: true });
        try {
            let response = await addToFavoriteService({
                token: this.props.sessionProps.account?.token,
                lotId: item.id,
                auctionId: item.id_remate,
            });
            let lots = this.state.auction.lots;
            let msg = 'Tu lote ha sido agregado a favoritos con éxito';
            if (item.isFavorite) {
                msg = 'Tu lote ha sido eliminado de favoritos con éxito';
            }

            const favoritesLotsId = []
            Object.entries(lots).forEach(([key, lot]) => {
                if (lot.id === item.id) {
                    item.isFavorite = !item.isFavorite;
                    lots[key] = item
                }
                if (lot.isFavorite) {
                    favoritesLotsId.push(lot.id)
                }
            });

            let tab = !favoritesLotsId.length ? 'all' : this.state.tab;
            await this.setState({
                loading: false,
                auction: {
                    ...this.state.auction,
                    lots
                },
                tab
            });

            toast(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error.response?.status === 401) {
                this.props.logout();
                this.props.history.push(`/ingresar/${this.state.id}/${this.state.positionY}/subastas`);
            }
            else {
                let msg = 'No fue posible agregar tu lote a favoritos, reintentar nuevamente';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    getIconFavorite = (item) => {
        if (![Constants.STATUS_PENDING, Constants.STATUS_DELIVERED, Constants.STATUS_NOT_AUCTIONED].includes(item.status)) {
            if (item && item.isFavorite) {
                return <div style={{
                    width: 'max-content',
                    background: 'transparent',
                    position: 'absolute',
                    top: '0rem',
                    right: '0rem',
                    cursor: 'pointer',
                    zIndex: '4',
                }} onClick={() => this.addToFavorite(item)}>
                    <i className="fas fa-heart" style={{ fontSize: '24px', color: '#e91702' }}></i>
                </div>;
            }
            return <div style={{
                width: 'max-content',
                background: 'transparent',
                position: 'absolute',
                top: '0rem',
                right: '0rem',
                cursor: 'pointer',
                zIndex: '4',
            }} onClick={() => this.addToFavorite(item)}> <i className="far fa-heart" style={{ fontSize: '24px', color: '#e91702' }}></i>
            </div>;
        }
        return (<React.Fragment></React.Fragment>);
    }

    Card2 = ({ item, index }) => (
        <React.Fragment>
            <div className={`${this._countDown({ item }) !== null ? 'col-lg-3 col-md-6' : 'col-lg-4 col-md-6'} products-col-item`}>
                {
                    this.countDown({ item })
                }
                <div className="single-product-box-new">
                    <span style={{ border: '1px solid #e9e9e9', borderBottom: 'none' }}>
                        <div className="product-image">
                            <div className="single-collections-box">
                                {
                                    item.extraImages ?
                                        <Carousel variant="dark" interval={null}>
                                            {item.extraImages.map(image =>
                                                <Carousel.Item>
                                                    <img
                                                        onClick={() => this.updateUrl(`/lotes/${item.id}/${this.state.positionY}`)}
                                                        style={item.status === 2 || item.status === 3 || item.status === 4 ? { opacity: '0.5' } : {}}
                                                        className="d-block w-100"
                                                        src={image.image}
                                                        alt="Lote sin imagen"
                                                    />
                                                </Carousel.Item>
                                            )}
                                        </Carousel>
                                        : <img onClick={() => this.updateUrl(`/lotes/${item.id}/${this.state.positionY}`)}
                                            src={item.image_lot !== null ? item.image_lot : item.image_remision} alt="Lote sin imagen" />
                                }

                            </div>
                        </div>
                    </span>

                    <div className="product-content text-left" style={{ padding: '0.5rem' }}>
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <h5 className='primary-color font-weight-bold' >Lote: {+item.id_lot}</h5>
                            {
                                this.getIconFavorite(item)
                            }
                        </div>
                        <p className='primary-color' style={{ marginBottom: '0.1rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '16px' }}>
                            {item.title}
                        </p>
                        {
                            item.description === null || item.description === '' ?
                                <p style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '15px', fontStyle: 'italic' }}>Lote sin descripción</p>
                                : <p className='description-lot' dangerouslySetInnerHTML={{ __html: item.description }} />
                        }
                        <p className='primary-color d-flex flex-row justify-content-between' style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '15px' }}>
                            <b>Cantidad:</b>
                            <div class="quantity-container">
                                <button class={`quantity-button red ${item.status !== Constants.STATUS_IN_PROGRESS || item.quantity <= 1 || item.quantitySelect <= 1 || (this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer) ? 'disabled' : ''}`} onClick={() => { item.quantitySelect -= 1 }}
                                    disabled={item.status !== Constants.STATUS_IN_PROGRESS || item.quantity <= 1 || item.quantitySelect <= 1 || (this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer)}
                                >-</button>
                                <input type="text" class="quantity-input" value={`${item.quantitySelect}/${item.quantity}`} disabled />
                                <button class={`quantity-button green ${item.status !== Constants.STATUS_IN_PROGRESS || item.quantity === 1 || item.quantitySelect === item.quantity || (this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer) ? 'disabled' : ''}`}
                                    onClick={() => { item.quantitySelect += 1 }}
                                    disabled={item.status !== Constants.STATUS_IN_PROGRESS || item.quantity === 1 || item.quantitySelect === item.quantity || (this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer)}>+</button>
                            </div>
                        </p>
                        {
                            item.location ? (
                                <p className='primary-color d-flex flex-row justify-content-between' style={{ marginBottom: '0px', textAlign: 'right', fontSize: '15px' }}>
                                    <b>Ubicación:</b> {item.location}
                                </p>
                            ) : null
                        }
                        <p className='primary-color d-flex flex-row justify-content-between' style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '15px' }}>
                            <b className='primary-color'>Cierre:</b>
                            {
                                item.date_close === '2050-01-01 00:00:00' ?
                                    ' Cierre presencial' :
                                    ` ${this.capitalize(moment(item.date_close).format('dddd D'))}, ${moment(item.date_close).format('HH:mm')}hs.`
                            }
                        </p>
                        <p className='primary-color d-flex flex-row justify-content-between' style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '15px' }}><b className='primary-color'>Precio base:</b>{item.price_base > 0 ?
                            ` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.price_base)}` : ' Sin precio inicial'}</p>
                        {
                            item.status === Constants.STATUS_IN_PROGRESS && (item.message === undefined || item.message === null) ?
                                <React.Fragment>
                                    <p className='primary-color d-flex flex-row justify-content-between' style={{ marginBottom: '0', fontSize: '15px', textOverflow: 'ellipsis', overflow: 'hidden' }}><b className='primary-color'>Oferta actual:</b>
                                        {` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.offer)}`}
                                    </p>
                                    {
                                        item.bestUserNameOffer &&
                                        <p className='primary-color d-flex flex-row justify-content-between' style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '15px' }}><b>Mejor postor:</b> {item.bestUserNameOffer}</p>
                                    }
                                    <div className="buy-checkbox-btn">
                                        <div className="item">
                                            {
                                                this.props.sessionProps.account === null &&
                                                <Link to={`/ingresar/${this.state.id}/${this.state.positionY}/subastas`}>
                                                    <button className={`btn btn-primary not-offered btn-mobile f-16 ${window.style_config?.buttons?.showHoverOfferButtons50 ? 'not-offered_hover_50' : 'not-offered_hover'}`}>
                                                        INGRESAR PARA OFERTAR
                                                    </button>
                                                </Link>
                                            }
                                            {
                                                (this.props.sessionProps.account !== null && this.props.sessionProps.account.id !== item.bestUserIdOffer) ?
                                                    item.participation ?
                                                        <Link to="#">
                                                            <button className={`btn primary-background rounded-pill text-white w-100 btn-mobile f-16
                                                            ${item.manualOffer > item.nextOffer ? 'losing-animation' : 'losing'} 
                                                            ${window.style_config?.buttons?.showHoverOfferButtons50 ? 'losing_hover_50' : 'losing_hover'}`}
                                                                onClick={() => this.offer(item)}>
                                                                Ofertar {` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.manualOffer)}`}
                                                            </button>
                                                        </Link>
                                                        :
                                                        <Link to="#">
                                                            <button style={{ backgroundColor: '#4F71F7', border: '1px solid #4F71F7' }} className={`p-2 border-1 rounded-pill text-white w-100 f-16"
                                                                btn-mobile ${window.style_config?.buttons?.showHoverOfferButtons50 ? 'not-offered_hover_50' : 'not-offered_hover'}`}
                                                                onClick={() => this.offer(item)}>
                                                                Ofertar {` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.manualOffer)}`}
                                                            </button>
                                                        </Link>
                                                    : null
                                            }
                                            {
                                                this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer &&
                                                <Link to="#">
                                                    <button className="p-2 f-16 rounded-pill font-weight-bold w-100 mt-2 secondary-background primary-color" disabled={true}>
                                                        <i className="fas fa-star"></i>&nbsp;&nbsp;Mejor oferta
                                                    </button>
                                                </Link>
                                            }
                                        </div>
                                        {
                                            this.props.sessionProps.account !== null && this.props.sessionProps.account.id !== item.bestUserIdOffer &&
                                            <div className="item text-center" style={{ marginTop: '10px' }}>
                                                <div className="product-add-to-cart">
                                                    <div className="input-counter rounded-pill" style={{ width: '100%', maxWidth: '100%' }} >
                                                        <input className="form-control rounded-pill primary-color" type="number"
                                                            min={item.nextOffer <= 100 && this.state.auction.moneySymbol === 'USD' ? 1 : item.nextOffer}
                                                            step={item.nextOffer <= 100 && this.state.auction.moneySymbol === 'USD' ? 1 : 10}
                                                            placeholder="Ingresar monto manual"
                                                            style={{ width: '100%', textAlign: 'center', border: '1px solid #24393E', height: '38px' }}
                                                            onChange={(event) => this.handleChange(event, item)} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.countDownCancelOffer({ item })
                                        }
                                        {
                                            !item.bestUserIdOffer && item.price_base > 0 ? (
                                                <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                                                    <button onClick={() => {
                                                        // confirmar y pide en un input el valor sugerido
                                                        let suggestedValue = '';
                                                        confirmAlert({
                                                            title: `Sugerir oferta`,
                                                            message: 'Ingrese el monto sugerido',
                                                            buttons: [
                                                                {
                                                                    label: 'Cancelar'
                                                                },
                                                                {
                                                                    label: 'Confirmar',
                                                                    onClick: () => {
                                                                        this.offer(item, suggestedValue)
                                                                    }
                                                                }
                                                            ],
                                                            childrenElement: () => (
                                                                <>
                                                                    <br />
                                                                    <input
                                                                        type="number"
                                                                        placeholder='Monto sugerido'
                                                                        onChange={(event) => suggestedValue = event.target.value} />
                                                                </>
                                                            )
                                                        });
                                                    }} style={{ background: 'transparent', border: 'none', color: 'blue', textDecoration: 'underline' }}>
                                                        Sugerir oferta
                                                    </button>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {
                                        item.status === Constants.STATUS_PENDING || item.status === Constants.STATUS_DELIVERED ?
                                            <React.Fragment>
                                                {
                                                    item.bestUserNameOffer &&
                                                    <p className='primary-color d-flex flex-row justify-content-between' style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '15px' }}><b>Mejor postor:</b> {item.bestUserNameOffer}</p>
                                                }
                                                {
                                                    this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer ?
                                                        <Link to="#">
                                                            <button className="p-2 f-16 rounded-pill font-weight-bold w-100 mt-2 secondary-background primary-color" disabled={true}>
                                                                <i className="fas fa-star"></i>&nbsp;&nbsp;Subastado&nbsp;{this.state.auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(item.offer)}
                                                            </button>
                                                        </Link>
                                                        :
                                                        <Link to="#" className="noHover">
                                                            <button disabled={true} className={`p-2 rounded-pill w-100 text-white text-white primary-background f-16`} >
                                                                <CheckCircle color={'white'} size={20} />&nbsp;&nbsp; Subastado {this.state.auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(item.offer)}
                                                            </button>
                                                        </Link>
                                                }
                                            </React.Fragment> : null
                                    }
                                    {
                                        item.status === Constants.STATUS_NOT_AUCTIONED &&
                                        <React.Fragment>
                                            {
                                                item.bestUserNameOffer &&
                                                <p className='primary-color d-flex flex-row justify-content-between' style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '15px' }}><b>Mejor postor:</b> {item.bestUserNameOffer}</p>
                                            }
                                            <Link to="#" className="noHover">
                                                {
                                                    <button className="btn w-100 rounded-pill btn-danger f-16" disabled={true}>
                                                        <DashCircle color={'white'} size={20} />&nbsp;&nbsp; NO subastado
                                                    </button>
                                                }
                                            </Link>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    closeModalMessageErrorOffer = async () => {
        await this.setState({
            messageErrorOffer: false
        });
    }

    render() {
        return (
            <section className={`products-collections-area ${this.props.sessionProps.account !== null ? 'pb-60' : 'ptb-60'}`}>
                {this.state.loading && <Loading />}
                {this.state.messageErrorOffer && <ShowMessage msg={this.state.messageErrorOffer}
                    close={() => this.closeModalMessageErrorOffer()} color={this.color} />}
                <div className="container">
                    {
                        this.props.sessionProps.account !== null &&
                        <div className="row" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                            <div className="col-12 text-right">
                                <div style={{ width: '100%', whiteSpace: 'nowrap', display: 'flex', flexWrap: 'wrap' }}>
                                    <span onClick={() => this.props.history.push(`/mi-cuenta/1/${this.state.id}/subastas`)} className="item-myaccount"> <i className="fas fa-user"></i> Mi cuenta</span>
                                    <span onClick={() => this.props.history.push(`/mi-cuenta/2/${this.state.id}/subastas`)} className="item-myaccount"><i className="fas fa-gavel"></i>  Mis ofertas</span>
                                    {
                                        /*<span onClick={() => this.props.history.push(`/favoritos/${this.state.id}`)} className="item-myaccount"><i className="fas fa-heart"></i>  Mis Favoritos</span>
                                        */
                                    }
                                    <span onClick={() => this.props.history.push(`/mi-cuenta/3/${this.state.id}/subastas`)} className="item-myaccount"><i className="fas fa-shopping-basket"></i>  Mis Compras</span>
                                    {
                                        /*this.props.sessionProps.account.remitter &&
                                        <span onClick={() => this.props.history.push(`/mi-cuenta/4/${this.state.id}/subastas`)} className="item-myaccount"><i className="fas fa-cash-register"></i>  Mis Ventas</span>*/
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div className="section-title text-center">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="login-content d-flex justify-content-center">
                                    <div className="auction-title text-center d-flex flex-column justify-content-center align-content-center">
                                        <h4 className='display-5 font-weight-bold'>{this.state.auction?.title}</h4>
                                        <hr className='yellow-separator rounded-pill align-self-center' />
                                    </div>
                                </div>
                                {
                                    this.state.auction.catalog !== '' && this.state.auction.catalog !== null &&
                                    <a className="auction-in-proccess catalog" href={`${Constants.BASE_URL}${this.state.auction.catalog}`} target="_blank" style={{ width: 'auto' }}>
                                        <img src={this.props.configAccount.config?.icon_download_catalog ? Constants.BASE_URL + this.props.configAccount.config.icon_download_catalog : ''} style={{ width: '4rem', cursor: 'pointer' }} />
                                    </a>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="box-subastas">
                        <div className="row">
                            <div className='col-12 col-md-9'>
                                <div className='d-flex align-items-center' style={{ gap: '0.5rem' }}>
                                    <Calendar2 className='primary-color' size={40} />
                                    <span className='f-16' style={{ color: '#666666', fontWeight: 'bold' }}>
                                        {!this.state.loading && `Inicio: ${moment(this.state.auction.date_from).format('dddd D')} de ${moment(this.state.auction.date_from).format('MMMM')}, ${moment(this.state.auction.date_from).format('HH:mm')}`}<br />
                                        {!this.state.loading && !this.state.auction.isPreOferta ? `Cierre: ${moment(this.state.auction.date_to).format('dddd D')} de ${moment(this.state.auction.date_to).format('MMMM')}, ${moment(this.state.auction.date_to).format('HH:mm')}`
                                            : this.state.auction.isPreOferta ? 'Cierre: presencial' : ''}
                                    </span>
                                </div>
                            </div>
                            {
                                !this.state.auction.isPreOferta ? (
                                    <div className='col-12 col-md-3'>
                                        <div className='auction-status-details'>
                                            <div className='primary-color font-weight-bold mb-2' >Estado de la subasta</div>
                                            {
                                                this.state.auction?.status === 'Subasta en proceso' ?
                                                    <div className='p-0 pb-1 pt-1 w-100 text-white btn-success rounded-pill text-center'>En proceso</div>
                                                    :
                                                    this.state.auction?.status === 'Subasta finalizada' ?
                                                        <div className='p-0 pb-1 pt-1 w-100 text-white btn-danger rounded-pill text-center'>Finalizada</div>
                                                        :
                                                        this.state.auction?.status === 'Cerrando subasta' ?
                                                            <div className='p-0 pb-1 pt-1 w-100 text-white btn-warning rounded-pill text-center'>Cerrando</div>
                                                            :
                                                            <div className='p-0 pb-1 pt-1 w-100 text-white btn-warning rounded-pill text-center'>Pendiente</div>
                                            }
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                        <div className="col-12 description-auction" dangerouslySetInnerHTML={{ __html: this.state.auction.description }} />
                    </div>

                    {
                        !this.state.auction.isPreOferta ? (
                            <div className="row" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                                <div className="col-12">
                                    {
                                        this.state.auction.pending &&
                                        <h5 className="auction-in-proccess">
                                            Inicia a la hora {`${moment(this.state.auction.date_from).format('HH:mm')} - `}Faltan
                                            {` ${this.state.auction.timeLeft.hour}:${this.state.auction.timeLeft.minute}:${this.state.auction.timeLeft.second}`}
                                        </h5>
                                    }
                                    {

                                        Object.values(this.state.auction.lots).filter(item => this._countDown({ item }) !== null).length > 0 || this.state.auction.status === 'Cerrando subasta' ?
                                            <h5 class='auction-in-proccess red-status'>Cerrando subasta</h5>
                                            :
                                            this.state.auction.status === 'Subasta en proceso' ?
                                                <h5 className="auction-in-proccess green-status">
                                                    {this.state.auction.status} - Comienzo cierre {`${this.capitalize(moment(this.state.auction.date_to).format('dddd D'))} hora ${moment(this.state.auction.date_to).format('HH:mm')}`}
                                                </h5>
                                                :
                                                this.state.auction.status === 'Subasta finalizada' ?
                                                    <h5 class='red-status'>{this.state.auction.status}</h5>
                                                    :
                                                    <h5>
                                                        {!this.state.loading && `Comienzo ${moment(this.state.auction.date_from).format('dddd D')} de ${moment(this.state.auction.date_from).format('MMMM')}
                                                    hora ${moment(this.state.auction.date_from).format('HH:mm')}`}
                                                    </h5>
                                    }
                                </div>
                            </div>
                        ) : null
                    }

                    <div className="row mt-5 tabs-selection">
                        <div className="col-12 text-right">
                            <button className={`${this.state.tab === 'all' ? 'active' : ''}`}
                                onClick={() => {
                                    this.setState({
                                        tab: 'all'
                                    })
                                }}>Todos</button>
                            <span>|</span>
                            <button
                                className={`${this.state.tab === 'favorites' ? 'active' : ''}`}
                                onClick={() => {
                                    if (!Object.values(this.state.auction.lots).filter(item => item.isFavorite).length) {
                                        toast.error('Aún no agregaste ningun lote a favoritos')
                                        return
                                    }
                                    this.setState({
                                        tab: 'favorites'
                                    })
                                }}>Favoritos</button>
                        </div>
                    </div>

                    {
                        this.state.tab === 'favorites' ? (
                            <>
                                <div className="row">
                                    {
                                        Object.values(this.state.auction.lots).filter(item => item.isFavorite).map((item, index) =>
                                            this._countDown({ item }) !== null &&
                                            <this.Card2 item={item} index={index} />
                                        )
                                    }
                                </div>
                                <div className="row">
                                    {
                                        Object.values(this.state.auction.lots).filter(item => item.isFavorite).map((item, index) =>
                                            (item.status === Constants.STATUS_IN_PROGRESS || item.status === Constants.STATUS_INITIAL) && this._countDown({ item }) === null &&
                                            <this.Card2 item={item} index={index} />
                                        )
                                    }
                                </div>
                                <div className="row">
                                    {
                                        Object.values(this.state.auction.lots).filter(item => item.isFavorite).map((item, index) =>
                                            item.status !== Constants.STATUS_IN_PROGRESS && item.status !== Constants.STATUS_INITIAL && this._countDown({ item }) === null &&
                                            <this.Card2 item={item} index={index} />

                                        )
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="row">
                                    {
                                        Object.values(this.state.auction.lots).map((item, index) =>
                                            this._countDown({ item }) !== null &&
                                            <this.Card2 item={item} index={index} />
                                        )
                                    }
                                </div>
                                <div className="row">
                                    {
                                        Object.values(this.state.auction.lots).map((item, index) =>
                                            (item.status === Constants.STATUS_IN_PROGRESS || item.status === Constants.STATUS_INITIAL) && this._countDown({ item }) === null &&
                                            <this.Card2 item={item} index={index} />
                                        )
                                    }
                                </div>
                                <div className="row">
                                    {
                                        Object.values(this.state.auction.lots).map((item, index) =>
                                            item.status !== Constants.STATUS_IN_PROGRESS && item.status !== Constants.STATUS_INITIAL && this._countDown({ item }) === null &&
                                            <this.Card2 item={item} index={index} />

                                        )
                                    }
                                </div>
                            </>
                        )
                    }

                </div>
            </section >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCollectionsTwo);
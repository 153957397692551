import axios from 'axios';
import { Constants } from '../Constants';

export const getAllAuctions = async (data) => {
    try {
        return await axios.get(`${Constants.BASE_URL}public/auctions`);
    } catch (error) {
        throw error;
    }
}

export const getAuction = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(
            `${Constants.BASE_URL}public/auctions/${data.id}?interval=${data.interval}`,
            httpOptions
        );
    } catch (error) {
        throw error;
    }
}

export const getAuctionInProgress = async () => {
    try {
        return await axios.get(`${Constants.BASE_URL}public/getAuctionInProgress`);
    } catch (error) {
        throw error;
    }
}

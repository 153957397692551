import React from 'react';
import MegaMenu from './MegaMenu';

const Navbar = () => {
    return (
        <React.Fragment>
            <MegaMenu />
        </React.Fragment>
    );
}

export default Navbar;

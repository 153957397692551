import React from "react";
import { Link } from "react-router-dom";
import { Constants } from "../../Constants";
import moment from "moment";

const AuctionCard = ({ col, small, item, next, carousel }) => {
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <div className={carousel ? "col-12" : col}>
      {item.id ? (
        <Link to={`subastas/${item.id}`}>
          <div
            className={`single-product-box-new col-12 products-col-item ${
              small ? "pb-0 pl-0 pr-0 mb-5" : ""
            } `}
            style={{ height: small ? "420px" : next ? "360px" : "540px" }}
          >
            <div className="product-image p-2 rounded">
              <div className="single-collections-box">
                <img src={`${Constants.BASE_URL}${item.image}`} alt="lote" />
              </div>
            </div>
            <div
              style={{ height: small ? "140px" : "auto" }}
              className={`${
                small ? "secondary-background overflow-hidden rounded" : ""
              } post-content text-center d-flex justify-content-center flex-column mt-2`}
            >
              <h5
                className="primary-color"
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {item.title}
              </h5>
              {small || next ? (
                <p className="primary-color">
                  <b>Cierre:</b>&nbsp;
                  {capitalize(moment(item.date_to).format("dddd D"))} de{" "}
                  {capitalize(moment(item.date_to).format("MMMM"))}
                </p>
              ) : (
                <span className="mt-2 d-flex col-12 justify-content-center flex-column align-items-center">
                  <p className="d-flex flex-row">
                    <b>Inicio:</b>{" "}
                    <p className="primary-color">
                      &nbsp;
                      {capitalize(moment(item.date_from).format("dddd D"))} de{" "}
                      {capitalize(moment(item.date_from).format("MMMM"))}{" "}
                      {moment(item.date_from).format("HH:mm")}
                    </p>
                  </p>
                  <p className="d-flex flex-row">
                    <b>Cierre: </b>
                    <p className="primary-color"></p>&nbsp;
                    {capitalize(moment(item.date_to).format("dddd D"))} de{" "}
                    {capitalize(moment(item.date_to).format("MMMM"))}{" "}
                    {moment(item.date_to).format("HH:mm")}
                  </p>
                </span>
              )}
              {!small && !next ? (
                <button className="btn rounded-pill text-white primary-background font-weight-bold col-8 offset-2 mt-4">
                  Ir a la subasta
                </button>
              ) : null}
            </div>
          </div>
        </Link>
      ) : (
        <img src={`${Constants.BASE_URL}${item.image}`} alt="" />
      )}
    </div>
  );
};

export default AuctionCard;

import React from 'react';
import './ShowMessage.scss';
import { XCircle } from 'react-bootstrap-icons';

const ShowMessage = ({close, msg, color}) => {
    return (
        <React.Fragment>
            <div className="container-modal">
                <div className="content-modal">
                    <i onClick={() => close()} className="fas fa-times-circle"></i>
                    <div className='col-12 text-center align-items-center'>
                        <XCircle color='red' size={60} fontWeight={'bold'} />
                        <b><p style={{fontSize: 16}} className='mt-4 mb-4'>{msg}</p></b>
                        <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                            <button style={{fontSize: 18}} className='border-0 w-50 btn-danger rounded-pill p-2'
                                onClick={() => close()}
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ShowMessage;